// ================ ROUTES  =================
export const R_DASHBOARD = "/admin";
export const R_COMPANIES = "/admin/empresas-cadastradas";
export const R_AUDIOFOCO_USERS = "/admin/audiofoco-usuarios";
export const R_REWARDS = "/admin/recompensas";
export const R_ADD_REWARDS = "/admin/adicionar_recompensas";
export const R_EDIT_REWARDS = (reward_id) =>
  `/admin/editar_recompensa/${reward_id}`;
export const R_PLANS_USERS = "/admin/planos-do-usuario";
export const R_PLANS_CANCEL = "/admin/cancelar-plano";
export const R_DELETE_USER = "/admin/excluir-usuario";
export const R_GET_USER_DETAILS = (email) =>
  `/admin/planos-do-usuario/details/${email}`;
