import React, { useRef, useEffect, useState } from "react";
import classes from "./ModalAddJourneys.module.scss";
import { Button, Form, Input } from "antd";
import { Auth } from "aws-amplify";
import axios from "axios";
import { ADMIN_AUDIOFOCO_ADD_JOURNEY } from "../../../constants/api";

const ModalSuperAdmin = ({
    setShowModalAddJourney,
    setHasSuccessInUpdate,
    hasSuccessInUpdate,
    companyUid,
    planPermission,
}) => {
    const myRefName = useRef();
    let modalColor = null;

    let content_modal = null;

    const [openState, setModal] = useState(classes.WrapModalhide);
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [errorResponse, setErrorResponse] = useState(null);

    const sendUpdateJourney = (journeys) => {
        setIsLoading(true);
        setErrorResponse("");
        setResponse("");
        const numberJourneysSmart = journeys.numberJourneysSmart;
        const numberUsersGo = journeys.numberUsersGo;
        const company_uid = companyUid;
        const data = {
            companyUid: company_uid,
        };
        if (!numberJourneysSmart && !numberUsersGo) {
            setIsLoading(false);
            return setErrorResponse("insira um valor em um dos campos");
        }
        if (numberJourneysSmart) data.numberJourneysSmart = numberJourneysSmart;
        if (numberUsersGo) data.numberUsersGo = numberUsersGo;
        Auth.currentAuthenticatedUser().then((u) => {
            axios
                .put(ADMIN_AUDIOFOCO_ADD_JOURNEY, data, {
                    headers: {
                        Authorization:
                            Auth.user.signInUserSession.idToken.jwtToken,
                    },
                })
                .then((res) => {
                    setResponse(res);
                    setIsLoading(false);
                    setHasSuccessInUpdate(!hasSuccessInUpdate);
                })
                .catch((e) => {
                    console.log("e", e);
                    setIsLoading(false);
                    setErrorResponse(
                        "algo deu errado, por favor, tente mais tarde."
                    );
                });
        });
        setIsLoading(false);
    };

    useEffect(() => {
        setModal(classes.WrapModal);
    }, []);

    content_modal = (
        <div className={openState} ref={myRefName}>
            <div className={classes.ContentModalAdd}>
                <div className={modalColor}>
                    <div className={classes.Information}>
                        <div className={classes.Title}>
                            {`Adicionar jornadas/Usuários (${companyUid})`}
                        </div>
                        <div>
                            <div className={classes.ModalLabel}>
                                {response && response?.status === 200 ? (
                                    <div className={classes.sucessAdd}>
                                        Alteração feita com sucesso!!!
                                    </div>
                                ) : null}
                                {errorResponse ? (
                                    <div
                                        className={classes.ErrorMessage}
                                    >{`Erro: ${errorResponse}`}</div>
                                ) : null}
                            </div>
                        </div>
                        {planPermission === "10" || planPermission === "11" ? (
                            <Form
                                layout="inline"
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    maxWidth: 600,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={(e) => {
                                    sendUpdateJourney(e);
                                }}
                                onFinishFailed={(e) => {
                                    console.log(e);
                                }}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Smart"
                                    name="numberJourneysSmart"
                                    rules={[]}
                                    style={{ minWidth: "100px" }}
                                >
                                    <Input style={{ minWidth: 200 }} />
                                </Form.Item>

                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                ></Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={isLoading}
                                        isLoading={isLoading}
                                    >
                                        {isLoading ? "Enviando" : "Cadastrar"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        ) : null}
                        {planPermission === "01" || planPermission === "11" ? (
                            <Form
                                layout="inline"
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    maxWidth: 600,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={(e) => {
                                    sendUpdateJourney(e);
                                }}
                                onFinishFailed={(e) => {
                                    console.log(e);
                                }}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Go"
                                    name="numberUsersGo"
                                    rules={[]}
                                    style={{ minWidth: "100px" }}
                                >
                                    <Input style={{ minWidth: 200 }} />
                                </Form.Item>

                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                ></Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={isLoading}
                                        isLoading={isLoading}
                                    >
                                        {isLoading ? "Enviando" : "Cadastrar"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        ) : null}
                        <Button
                            type="primary"
                            onClick={() => setShowModalAddJourney(false)}
                        >
                            Fechar
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    return <section>{content_modal}</section>;
};

export default ModalSuperAdmin;
