import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "./UserDataDetails.module.scss";
import * as routes from "./../../constants/routes";
import axios from "axios";
import moment from "moment";
import H1 from "../../Components/Titles/H1";
import ButtonBack from "../../Components/Buttons/ButtonBack/ButtonBack";
import ButtonFunctionIncludePlan from "../../Components/Buttons/ButtonFunctionIncludePlan/ButtonFunctionIncludePlan";
import ModalIncludePlanOrAdd from "../../Components/Modais/ModalIncludePlanOrAdd/ModalIncludePlanOrAdd";
import ModalCancelRecurrence from "../../Components/Modais/ModalCancelRecurrence/ModalCancelRecurrence";
import ModalEditPlan from "../../Components/Modais/ModalEditPlan/ModalEditPlan";
import { Auth } from "aws-amplify";
import { ADMIN_GET_USERS_DATA } from "./../../constants/api";
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";
import Spinner from "./../../Components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Tag } from "antd";

const UserDataDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeModal, setTypeModal] = useState("recurrence");
  const [showModalCancelRecurrence, setShowModalCancelRecurrence] =
    useState(false);
  const [showModalEditPlan, setShowModalEditPlan] = useState(false); //

  const [planSelect, setPlanSelect] = useState({});
  const [forceUpdate, setForceUpdate] = useState(0);
  const [productParentMap, setProductParentMap] = useState({});
  const [state, setState] = useState({
    error: "",
    progress: 0,
    users: {},
    all_users: [],
    user_email: "",
    user_sub: "",
    product_name: "",
    modal_visible: false,
    modal_visible_free: false,
    loading: false,
    mensagem: 0,
    message_no_user: false,
    change_plan: false,
  });

  const map_color_tag = {
    Único: "geekblue",
    Recorrente: "green",
    Legado: { true: "volcano", false: "geekblue" },
    Status: { active: "green", inactive: "volcano" },
  };
  useEffect(() => {
    if (
      Object.keys(state.users).length == 0 ||
      forceUpdate ||
      state.users.email != props.match.params.email
    ) {
      setLoading(true);
      Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_GET_USERS_DATA(props.match.params.email), {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((users) => {
          setState({ ...state, users: users.data });
          // if('location' in props && 'state' in props.location && 'user' in props.location.state)
          // props.location.state.user = users.data
          // debugger
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.email, forceUpdate]);

  useEffect(() => {
    if (state?.users?.plans) {
      let newobj = state.users.plans.reduce((obj, item, i) => {
        if (!(item["parent_product_id"] in obj))
          obj[item["parent_product_id"]] = [item.product_id];
        else if (
          item["status"] == "active" &&
          item["recurrence"] == "true" &&
          obj[item["parent_product_id"]].indexOf(item.product_id) == -1
        )
          obj[item["parent_product_id"]].push(item.product_id);
        return obj;
      }, {});
      for (let key in newobj) {
        newobj[key].forEach((element) => {
          if (element in newobj) {
            newobj[key] = [...newobj[key], ...newobj[element]];
          }
        });
      }

      setProductParentMap(newobj);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.email, forceUpdate]);

  const renderUser = (plan, k, sizeAll) => {
    return (
      <tr className={classes.DataTable} key={sizeAll - k}>
        <th scope="row">{sizeAll - k}</th>
        <td>{plan.orderID ? plan.orderID : "-"}</td>
        <td>{plan.application_name}</td>
        <td>{plan.name}</td>
        <td>
          {plan.periodicity === "monthly"
            ? "Mensal"
            : plan.periodicity === "annual"
            ? "Anual"
            : plan.periodicity
            ? plan.periodicity
            : "Sem Periodicidade"}
        </td>

        <td>
          <Tag color={map_color_tag["Legado"][plan.is_legacy]} key={k}>
            {plan.is_legacy === "true" ? "Legado" : "Normal"}
          </Tag>
        </td>
        <td>
          <Tag color={map_color_tag["Status"][plan.status]} key={k}>
            {plan.status === "active" ? "ATIVO" : "INATIVO"}
          </Tag>
        </td>
        <td>{moment(plan.next_charge).format("DD/MM/YYYY")}</td>
        <td>{plan.payment_method ? plan.payment_method : "-"}</td>
        <td>
          {plan.payment_method !== "creditcard" ||
          plan.recurrence == "false" ||
          plan.status == "inactive" ? (
            "Sem Recorrência"
          ) : (
            <button
              className={classes.ButtonCancel}
              onClick={() => {
                setPlanSelect(plan);
                setTypeModal("recurrence");
                setShowModalCancelRecurrence(!showModalCancelRecurrence);
              }}
            >
              Cancelar Recorrência
            </button>
          )}
        </td>
        <td>
          {plan.status == "inactive" ? (
            "Plano Já Inativo"
          ) : plan.type == "paid" ? (
            <button
              className={classes.ButtonCancel}
              onClick={() => {
                setPlanSelect(plan);

                setTypeModal("plan");

                setShowModalCancelRecurrence(!showModalCancelRecurrence);
              }}
            >
              Cancelar Plano
            </button>
          ) : (
            "Plano Free"
          )}

          <br />

          {plan.type !== "free" &&
          plan.status == "active" &&
          (plan.payment_method !== "creditcard" ||
            plan.recurrence === "false") ? (
            <button
              className={classes.ButtonEdit}
              onClick={() => {
                setPlanSelect(plan);

                setShowModalEditPlan(!showModalEditPlan);
              }}
            >
              {" "}
              Editar
            </button>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  };

  const renderUsersTable = (plans) => {
    return (
      <div>
        <table className="table">
          <thead>
            <tr className={classes.DataTable}>
              <th scope="col">#</th>
              <th scope="col">OrderID</th>
              <th scope="col">Produto</th>
              <th scope="col">Plano</th>
              <th scope="col">Periodicidade</th>
              <th scope="col">Legado</th>
              <th scope="col">Status</th>
              <th scope="col">Próxima Cobrança</th>
              <th scope="col">Forma de Pagamento</th>
              <th scope="col">Ações da Recorrência</th>
              <th scope="col">Ações do Plano</th>
            </tr>
          </thead>
          <tbody>
            {plans
              .sort((a, b) => b.id - a.id)
              .map((plan, k = +1, all) => renderUser(plan, k, all.length))}
          </tbody>
        </table>
        <hr />
      </div>
    );
  };
  const renderInfoTable = (users) => {
    let userInfo = users;
    userInfo = userInfo;

    return (
      <div className={classes.CardInfoUser}>
        <div className={classes.LineInfoUser}>
          <b>Nome:</b> {userInfo?.given_name}
        </div>
        <div className={classes.LineInfoUser}>
          <b>E-mail:</b> {userInfo?.email}
        </div>
        <div className={classes.LineInfoUser}>
          <b>Sub:</b> {userInfo?.sub}
        </div>
        <div className={classes.LineInfoUser}>
          <b>Profissão:</b> {userInfo?.profession}
        </div>
        <div className={classes.LineInfoUser}>
          <b>N° de Usuários Vinculados:</b>{" "}
          {userInfo?.children_amt > 0
            ? userInfo?.children_amt + " Usuário(s) "
            : "Sem Usuários"}
          <Link
            to={{
              pathname: `/admin/planos-do-usuario/user/${userInfo?.sub}`,

              state: { emailParent: userInfo.email },
            }}
          >
            {" Ver Usuários"}
          </Link>
        </div>
        <div className={classes.LineInfoUser}>
          <b>N° de Jogadas do Usuário:</b>{" "}
          {userInfo?.scores_amt > 0
            ? userInfo?.scores_amt + " Jogadas"
            : "Usuário Sem Jogadas"}
        </div>
        <div className={classes.LineInfoUser}>
          <b>Possui Um Usuário Pai:</b>{" "}
          {userInfo.parent_email ? (
            <Link
              to={{
                pathname: routes.R_GET_USER_DETAILS(userInfo.parent_email),
                state: { parentSub: userInfo.sub },
              }}
            >
              {userInfo.parent_email}
            </Link>
          ) : (
            "Não"
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <H1>
        PLANOS DO USUÁRIO{" "}
        {!loading ? (
          <Button
            shape="circle"
            onClick={() => {
              setForceUpdate(forceUpdate + 1);
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateLeft} />
          </Button>
        ) : (
          <Spinner></Spinner>
        )}
      </H1>
      {showModal && (
        <ModalIncludePlanOrAdd
          users={state.users}
          setShowModal={setShowModal}
          setForceUpdate={setForceUpdate}
          forceUpdate={forceUpdate}
        />
      )}
      {showModalEditPlan && (
        <ModalEditPlan
          users={state.users}
          plan={planSelect}
          typeModal={typeModal}
          related={state.users.plans.filter(
            (item) =>
              planSelect.orderID == item.orderID &&
              (planSelect.orderID || planSelect.id == item.id)
          )}
          setShowModal={setShowModalEditPlan}
          setForceUpdate={setForceUpdate}
          forceUpdate={forceUpdate}
        />
      )}
      {showModalCancelRecurrence && (
        <ModalCancelRecurrence
          plan={planSelect}
          typeModal={typeModal}
          related={state.users.plans.filter(
            (item) =>
              planSelect["product_id"] in productParentMap &&
              item.status == "active" &&
              item.recurrence == "true" &&
              productParentMap[planSelect["product_id"]].indexOf(
                item.product_id
              ) > -1
          )}
          setShowModalCancelRecurrence={setShowModalCancelRecurrence}
          setForceUpdate={setForceUpdate}
          forceUpdate={forceUpdate}
        />
      )}
      <ButtonBack text="Voltar" to="/admin/planos-do-usuario" />
      {"plans" in state.users && state.users.plans.length > 0 && (
        <ButtonFunctionIncludePlan
          text="incluir plano / adicional"
          onClick={() => setShowModal(!showModal)}
        />
      )}
      {!loading ? renderInfoTable(state.users) : <Spinner />}
      <div className="row">
        <div className="col-sm-12">
          {!loading && Object.keys(state.users).length > 0 ? (
            "plans" in state.users && state.users.plans.length > 0 ? (
              renderUsersTable(state.users.plans)
            ) : (
              "Não foi possível receber as informações do usuário, pode ser que ele esteja no Limbo."
            )
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDataDetails;
