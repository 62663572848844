import React from "react";
import classes from "./h1.module.scss";

const H1 = ({ iconLeft, iconRight, className = "", children }) => {
  let _content = children;
  const _iconLeft = iconLeft ? (
    <span className={classes.IconLeft}>{iconLeft}</span>
  ) : null;
  const _iconRight = iconRight ? (
    <span className={classes.IconRight}>{iconRight}</span>
  ) : null;
  const _finalClass = className ? className : "";
  return (
    <h1 className={_finalClass + " " + classes.H1}>
      {_iconLeft}
      {_content}
      {_iconRight}
    </h1>
  );
};
export default H1;
