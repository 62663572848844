//PROBRAIN-ADMIN PROD
const config = {
    userPoolId: 'us-east-1_zqZphUs93',
    region: 'us-east-1',
    userPoolWebClientId: '6r9bhtsvtuj8jibm9fd00il4hc'
}

//MARATONA DEV
/*const config = {
    userPoolId: 'us-east-1_GRy6T2BH2',
    region: 'us-east-1',
    userPoolWebClientId: '47jbiadgaevrpnfspmtd4h3rdn'
}*/

//MARATONA PROD
/*const config = {
    userPoolId: 'us-east-1_OF9DS5Zhk',
    region: 'us-east-1',
    userPoolWebClientId: '4va4mmb613ui3h1k1o18ohph6r'
}*/

/*
    us-east-1_3XNVn6p8M
    13b9njldu2teup7adka1h4sc8r
*/


export default config
