import React, { useRef, useEffect, useState } from "react";
// import ReactDOMServer from "react-dom/server";
import classes from "./ModalUserDeleted.module.scss";
import { Auth } from "aws-amplify";
import axios from "axios";
import { ADMIN_REMOVE_USER } from "../../../constants/api";
import Spinner from "../../Spinner";
import { Link, Route } from "react-router-dom";
import * as routes from "../../../constants/routes";

const ModalUserDeleted = ({ users, setShowModalDeleted }) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;

  let valueDigited = "";

  const [loginInfo, setLoginInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openState, setModal] = useState(classes.WrapModalhide);

  useEffect(() => {
    setModal(classes.WrapModal);
  }, []);

  //Content
  content_modal = (
    <div className={openState} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setModal(classes.WrapModalhide);
                setShowModalDeleted(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>
              O USUÁRIO FOI EXCLUÍDO COM SUCESSO!
            </div>
            <div className={classes.OptionsYesOrNo}>
              <Link
                to={routes.R_DELETE_USER}
                tabIndex="0"
                onClick={() => {
                  window.location.reload();
                  setModal(classes.WrapModalhide);
                  setShowModalDeleted(false);
                }}
                className={classes.CloseButtonText}
              >
                FECHAR
              </Link>
            </div>
            {error != "" && <div className={classes.ErrorMessage}>{error}</div>}

            {loading && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalUserDeleted;
