import React, { useState, useEffect } from "react";
import classes from "./TopNav.module.scss";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import LogoBeta from "../../assets/images/logo_horizontal.png";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopNav = (props) => {
  const history = useHistory();
  const [isUserNavOpen, toggleUserNav] = useState(false);
  const [loginInfo, setLoginInfo] = useState();

  const resetNavs = () => {
    toggleUserNav(false);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setLoginInfo(user.attributes.email);
      })
      .catch(() => {});
  }, []);

  const getUserAvatarSection = (email) => {
    let click = () => {
      resetNavs();
      Auth.signOut().then((res) => {
        history.push("/");
      });
    };
    return (
      <>
        <div className={classes.UserMenu}>
          <button tabIndex={0} className={classes.BtnAvatar} onClick={click}>
            <p className={classes.Name}>
              <span>
                <b>Olá,</b>
              </span>
              <span className={classes.UserName}>{loginInfo}</span>
            </p>
            <FontAwesomeIcon
              className={classes.IconNav}
              icon={faRightFromBracket}
            />
          </button>
        </div>
      </>
    );
  };

  let _btnToggleMenu = null;
  return (
    <>
      <header className={classes.TopNav}>
        <section className={classes.DesktopNav}>
          <section className={classes.LeftMenu}>
            <div className={classes.Logo}>
              <Link onClick={() => resetNavs()} to={() => {}}>
                <div className={classes.LogoPosition}>
                  <figure>
                    <img src={LogoBeta} />
                  </figure>
                </div>
              </Link>
            </div>
          </section>

          <div className={classes.RightMenu}>{getUserAvatarSection()}</div>
          {_btnToggleMenu}
        </section>
        {isUserNavOpen && (
          <div
            className={isUserNavOpen ? classes.Overlay : ""}
            onClick={() => resetNavs()}
          ></div>
        )}
        <div
          className={
            isUserNavOpen
              ? classes.NavRight + " " + classes.OpenRNav
              : classes.NavRight
          }
        ></div>
      </header>
    </>
  );
};

export default TopNav;
