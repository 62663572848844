import React, { useRef, useEffect, useState } from "react";
// import ReactDOMServer from "react-dom/server";
import classes from "./ModalIncludePlanOrAdd.module.scss";
import { Auth } from "aws-amplify";

import { Row, Col, Divider } from "antd";
// import Select from "../../Select/Select";
import axios from "axios";
import { ADMIN_ADD_PLAN_FROM_USER } from "../../../constants/api";
// import { DatePicker, Space } from "antd";
import moment from "moment";
import InputMask from "react-input-mask";

import CurrencyInput from "./../../Inputs/CurrencyInput/CurrencyInput";
import Spinner from "./../../Spinner";

const ModalIncludePlanOrAdd = ({
  users,
  setShowModal,
  setRerenderModal,
  forceUpdate,
  setForceUpdate,
}) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;

  let valueDigited = "";

  const [loginInfo, setLoginInfo] = useState();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openState, setModal] = useState(classes.WrapModalhide);
  const [canAdd, setCanAdd] = useState(false);
  const [ProductValueDigited, setProductValueDigited] = useState("");
  const [PlanAddValueDigited, setPlanAddValueDigited] = useState("");
  const [PaymentMethodValueDigited, setPaymentMethodValueDigited] =
    useState("");
  const [NextChargeValueDigited, setNextChargeValueDigited] = useState("");
  const [ObservationsValueDigited, setObservationsValueDigited] = useState("");
  const [PaymentPriceValueDigited, setPaymentPriceValueDigited] = useState("");
  // const [valueMask, setValueMask] = useState("");
  // const changeMask = (value) => {
  //   if (value === "0") {
  //     setValueMask("R$99,99");
  //   } else if (value === "1") {
  //     setValueMask("R$999,99");
  //   }
  //   console.log(value);
  // };

  useEffect(() => {
    setModal(classes.WrapModal);
  }, []);

  const addPlan = () => {
    Auth.currentAuthenticatedUser().then((u) => {
      let data = {
        admin_email: loginInfo,
        email: users?.email,
        product: ProductValueDigited,
        plan_addon: PlanAddValueDigited,
        payment_method: PaymentMethodValueDigited,
        periodicity: "custom",
        price: PaymentPriceValueDigited,
        next_charge: NextChargeValueDigited,
        observations: ObservationsValueDigited,
        key: "daj77A76TDN2ADx",
      };

      axios
        .post(ADMIN_ADD_PLAN_FROM_USER, data, {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((resp) => {
          console.log(resp);
          setLoading(false);
          setForceUpdate(forceUpdate + 1);
          setShowModal(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setError("Usuário não tem permissão ou já possui o produto.");
        });
    });
  };
  useEffect(() => {
    validateAddPlan();
  }, [
    ProductValueDigited,
    PlanAddValueDigited,
    PaymentMethodValueDigited,
    PaymentPriceValueDigited,
    NextChargeValueDigited,
  ]);

  const validateAddPlan = () => {
    let data = {
      admin_email: loginInfo,
      email: users?.email,
      product: ProductValueDigited,
      plan_addon: PlanAddValueDigited,
      payment_method: PaymentMethodValueDigited,
      periodicity: "custom",
      price: PaymentPriceValueDigited,
      next_charge: NextChargeValueDigited,
      key: "daj77A76TDN2ADx",
    };
    let _canAdd = true;
    for (let key in data) {
      if (!data[key] || data[key] == "Invalid date") {
        _canAdd = false;
      }
    }
    setCanAdd(_canAdd);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user.attributes.email);
        setLoginInfo(user.attributes.email);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // var select = document.getElementById("language");
  // var text = select.options[select.selectedIndex].text;
  // console.log(text);

  let SelectProduct = (
    <select
      id="product"
      className={classes.Dropdown}
      onChange={(e) => {
        let valueSelect = e.target.value;
        setProductValueDigited(valueSelect);
        setPlans(users.products.filter(item=>item.application===valueSelect && item.type==='paid').map((item,i)=>{
          return {
            "name":item.name,
            "id":item.id
          }
        }))
      }}
    >
      <option className={classes.Option} value="Selecione um Produto">
        Selecione um Produto
      </option>
      {users.products
        .reduce((acc, item) => {
          const obj = {
            application: item.application,
            name: item.application_name,
          };
          const finded = acc.reduce((f, i, _i) => {
            if (f === -1 && i.application === item.application) return _i;
            else return -1;
          }, -1);
          if (finded == -1) acc.push(obj);
          return acc;
        }, [])
        .map((item) => {
          return (
            <option className={classes.Option} value={item.application}>
              {item.name}
            </option>
          );
        })}
    </select>
  );
  let SelectPlanAdd = (
    <select
      className={classes.Dropdown}
      onChange={(e) => {
        let valueSelect = e.target.value;
        setPlanAddValueDigited(valueSelect);
      }}
    >
      <option className={classes.Option} value="Selecione um Plano/Adicional">
        Selecione um Plano/Adicional
      </option>
      {plans.map((item,i)=>{
        return <option className={classes.Option} value={item.name} >{item.name}</option>
      })}
    </select>
  );

  let SelectPaymentMethod = (
    <select
      className={classes.Dropdown}
      onChange={(e) => {
        let valueSelect = e.target.value;
        setPaymentMethodValueDigited(valueSelect);
      }}
    >
      <option className={classes.Option} value="Selecione Forma de Pagamento">
        Selecione Forma de Pagamento
      </option>
      <option className={classes.Option} value="Cartão de Crédito">
        Cartão de Crédito
      </option>
      <option className={classes.Option} value="Cortesia">
        Cortesia
      </option>
      <option className={classes.Option} value="pix">
        Pix
      </option>
      <option className={classes.Option} value="Transferência">
        Transferência
      </option>
    </select>
  );

  //Content
  content_modal = (
    <div className={openState} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setModal(classes.WrapModalhide);
                setShowModal(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>Inclusão de Plano / Adicional!</div>
            {error != "" && <div className={classes.ErrorMessage}>{error}</div>}

            <div>
              <div className={classes.ModalLabel}>
                <div>E-mail:</div>
                <Divider
                  orientation="center"
                  className={classes.ModalUserEmail}
                >
                  {users?.email}
                  {/* {console.log(users[0]?.email)} */}
                </Divider>
                <Row gutter={[16, 24]}>
                  <Col className="gutter-row" span={12}>
                    <div>Produto</div>
                    {SelectProduct}
                    <div>Plano/ Adicional:</div>
                    {SelectPlanAdd}
                    <div>Método de Pagamento:</div>
                    {SelectPaymentMethod}
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <div>Valor:</div>
                    <CurrencyInput
                      className={classes.InputModal}
                      placeholder="R$0,00"
                      tabindex="5"
                      type="text"
                      onChange={(e) => {
                        valueDigited = e.target.value
                          .replaceAll("R$", "")
                          .replaceAll(".", "");
                        setPaymentPriceValueDigited(valueDigited);
                      }}
                    />
                    <div>Data de Vencimento:</div>
                    <InputMask
                      tabindex="6"
                      required
                      maskChar={null}
                      className={classes.InputModal}
                      placeholder={"dd/mm/aaaa"}
                      mask="99/99/9999"
                      onChange={(e) => {
                        if (e.target.value.length === 10) {
                          let valueDigited = moment(
                            e.target.value,
                            "DD/MM/YYYY"
                          );
                          if (!valueDigited.isBefore(moment()))
                            setNextChargeValueDigited(valueDigited.format("YYYY-MM-DD"));
                          else
                            setNextChargeValueDigited("");
                        } else {
                          setNextChargeValueDigited("");
                        }
                      }}
                    />
                    <div>Observação:</div>
                    <input
                      className={classes.InputModal}
                      type="text"
                      onChange={(e) => {
                        let valueDigited = e.target.value;
                        setObservationsValueDigited(valueDigited);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <button
              tabIndex="0"
              disabled={loading ? loading : !canAdd}
              onClick={() => {
                addPlan();
                setLoading(true);
              }}
              className={classes.CloseButtonText}
            >
              Incluir Plano / Adicional
            </button>
            {loading && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalIncludePlanOrAdd;
