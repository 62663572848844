import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import App from './App'
import Login from './Login'
import * as serviceWorker from './serviceWorker'

// import './assets/css/style.css'
import 'antd/dist/antd.variable.min.css'
/* import { ConfigProvider } from 'antd';

const theme = {
  components :{
    Select:{
      borderColor:"red",
      borderRadius:"5px",
      backgroundColor:"green"
    }
  }
} */

ReactDOM.render(
  <React.StrictMode>
    {/* <ConfigProvider theme={theme}> */}
    <Router >
        <Switch>
          <Route exact path='/' name='login' component={Login} />
          <Route path='/admin' name='' component={App} />
        </Switch>
    </Router>
  {/* </ConfigProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
