import React, { useState, useEffect } from "react";
import classes from "../Recompensas.module.scss";
import { ADMIN_REWARDS ,TAG_MAP_RENDER} from "../../../constants/api";
import axios from "axios";
import { Auth } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Modal,
  Checkbox,
  Col,
  Row,
  AutoComplete,
  Tag,
  Divider, Space
} from "antd";
// import { ConfigProvider } from 'antd';

import Permission from "../../../Components/Permission/Permission";

const AddRewards = () => {
  const [inputValues, setInputValues] = useState({
    active: true,
    auto_claimed: true,
  });
  const [erroAddReward, setErroAddReward] = useState({});
  const [isAddingReward, setIsAddingReward] = useState(false);
  const [successAddReward, setSuccessAddReward] = useState("");
  const [rewards,setRewards] = useState([])

  const getRewards = () => {
    /* Auth.currentAuthenticatedUser().then((user) => {
      axios
        .get(ADMIN_REWARDS, {
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((response) => {
          setRewards(response?.data);
        })
        .catch((error) => {setRewards([])});
    }); */
    const _rewards = localStorage.getItem("rewards");
      if (_rewards) {
        setRewards(JSON.parse(_rewards));
      }
  };
  useEffect(() =>{
    if(rewards===null){
      getRewards()
    }
  },[rewards])


  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={(TAG_MAP_RENDER.hasOwnProperty(value)?TAG_MAP_RENDER[value]:"geekblue")}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };
  
  const rewardsElements = [
    {
      name: "reward_name",
      typeComponent: "input",
      type: "string",
      required: true,
    },
    {
      name: "category",
      typeComponent: "autocomplete",
      options: [
        { value: "ToyArts", label: "ToyArts" },
        { value: "ColoringPages", label: "ColoringPages" },
        { value: "AnswerSheets", label: "AnswerSheets" },
        { value: "WallpapersDesktop", label: "WallpapersDesktop" },
        { value: "WallpapersMobile", label: "WallpapersMobile" },
        { value: "GameLevels", label: "GameLevels" },
        { value: "GameCharacters", label: "GameCharacters" },
        { value: "Avatars", label: "Avatars" },
        {
          value: "Accessories (Afinandinho's World)",
          label: "Accessories (Afinandinho's World)",
        },
        { value: "Boosts", label: "Boosts" },
        { value: "LanguagePacks", label: "LanguagePacks" },
        { value: "OnlineClasses", label: "OnlineClasses" },
        { value: "PaperPuppets", label: "PaperPuppets" },
        { value: "MemoryGame", label: "MemoryGame" },
        { value: "SevenErrors", label: "SevenErrors" },
        { value: "TableTopDeck", label: "TableTopDeck" },
        { value: "FingerPuppet", label: "FingerPuppet" },
        { value: "DollDressUp", label: "DollDressUp" },
        { value: "AR", label: "AR" },
        { value: "WebComics", label: "WebComics" },
        { value: "Unlockables", label: "Unlockables" },
        { value: "CharacterSkin", label: "CharacterSkin" },
        { value: "ScenarySkin", label: "ScenarySkin" },
        { value: "BonusLevel", label: "BonusLevel" },
        { value: "Uncategorized", label: "Uncategorized" },
      ],
      type: "string",
      required: true,
    },
    {
      name: "tags",
      typeComponent: "select",
      type: "string",
      options: rewards && Object.keys(rewards.reduce((_tags,rw)=>{
        if(rw.tags){
          String(rw.tags).split(",").forEach(tag=>{
            if (!_tags.hasOwnProperty(tag)) _tags[tag] = tag
          })
        }
        return _tags
    },{})).map(_t=>{return {value:_t,label:_t}}),
      mode: "tags",
      required: false,
    },
    {
      name: "star_price",
      typeComponent: "input",
      type: "number",
      required: true,
    },
    {
      name: "reward_description",
      typeComponent: "textArea",
      type: "string",
      required: true,
    },
    {
      name: "reward_media",
      typeComponent: "input",
      type: "string",
      required: false,
    },
    {
      name: "max_instalments",
      typeComponent: "input",
      type: "number",
      required: false,
    },
    // { name: "resource", typeComponent: "input", type: "string", required: true },
    { name: "slug", typeComponent: "input", type: "string", required: true },
    {
      name: "thumbnail",
      typeComponent: "upload",
      type: "file",
      accept: ".png",
      required: true,
    },
    {
      name: "resource",
      typeComponent: "upload",
      type: "file",
      accept: "*",
      required: false,
    },
    // {"name": "permission", "typeComponent": "input", "type": "string", required: true},
    {
      name: "active",
      typeComponent: "checkbox",
      type: "string",
      required: false,
    },
    {
      name: "auto_claimed",
      typeComponent: "checkbox",
      type: "string",
      required: false,
    },
  ];



  const handleInputChange = (e, element) => {
    const value = e;
    console.log('handleInputChange',e,element)
    setInputValues({
      ...inputValues,
      [element]: value,
    });
  };

  const requiredFields = [
    "reward_name",
    "star_price",
    "slug",
    "reward_description",
    "category",
    "thumbnail",
  ];


  const getHeadTableNames = (name) => {
    const names = {
      id: "Id",
      reward_name: "Nome da recompensa",
      category: "Categoria",
      date_created: "Criado em",
      date_updated: "Modificado em",
      resource: "Arquivo",
      slug: "Nome único do item (sem espaços)",
      star_price: "Preço (Af$)",
      tags: "Tags",
      active: "Ativo",
      deletar: "Inativar",
      reward_description: "Descrição",
      reward_media: "URL do vídeo",
      auto_claimed: "Auto recuperável",
      max_instalments: "Máximo possuído",
      thumbnail: "Thumbnail",
      file: "Arquivo",
    };

    let fieldName = name in names ? names[name] : name;
    fieldName = requiredFields.includes(name) ? fieldName + "*" : fieldName;
    return fieldName;
  };

  const sendReward = (e) => {
    setIsAddingReward(true);
    setErroAddReward({});
    setSuccessAddReward("");

    const errors = {};
    if (
      Object.keys(inputValues).indexOf("slug") !== -1 &&
      inputValues.slug.split(" ").length > 1
    ) {
      errors["slug"] = "Este campo não pode conter espaços.";
    } else {
      delete errors["slug"];
    }
    if (
      Object.keys(inputValues).indexOf("max_instalments") !== -1 &&
      inputValues.max_instalments < 0
    ) {
      errors["max_instalments"] = "Este campo deve ser maior ou igual a zero";
    } else {
      delete errors["max_instalments"];
    }
    
    requiredFields.forEach((field) => {
      if (!inputValues[field] || (inputValues[field].trim!==undefined && inputValues[field].trim()=="")) {
        errors[field] = `"${getHeadTableNames(field)}" é um campo obrigatório!`;
      }
    });


    if (Object.keys(errors).length !== 0) {
      setIsAddingReward(false);
      setErroAddReward(errors);
      console.log(errors);
      return null;
    }

    const data = new FormData();
    const removeInfo = (objPermissions) => {
      const objPerm = JSON.parse(JSON.stringify(objPermissions))
      const _permission = Object.keys(objPerm).reduce((finalobj, key)=>{
        finalobj[key] = objPerm[key].map(obj=>{
          const _obj = obj
          if(_obj.hasOwnProperty("_info")) delete _obj["_info"]
          return _obj
        })
        
        return finalobj
      },{})
      return JSON.stringify(_permission)
    }

    const map_fields = {
      "active": (action) => action ? "Y" : "N",
      "auto_claimed": (action) => action ? "Y" : "N",
      "thumbnail": (action) => action.name,
      "resource": (action) => action.name,
      "permissions": (action) => removeInfo(action),
      "conditions": (action) => removeInfo(action)
    }

    Object.keys(inputValues).forEach((field) => {
      if(field in inputValues && inputValues[field]){
        if (field in map_fields) {
          data.append(field, map_fields[field](inputValues[field]));
        } else {
          data.append(field, inputValues[field]);
        }
      }
    });
    Auth.currentAuthenticatedUser().then((user) => {
      axios
        .post(ADMIN_REWARDS, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((response) => {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

          console.log("response", response);
          const responseData = response.data
          let responseCheck = {}
          const checkResponseFiles = () => {
            let allOk = true;
            Object.keys(responseCheck).forEach((field) => {
              allOk = allOk && (responseCheck[field] != null)
            })
            return allOk
          }
          Object.keys(responseData).forEach((field) => {
            const dataFile = new FormData();

            responseCheck[field] = null
            Object.keys(responseData[field]['fields']).forEach((inputField) => {
              dataFile.append(inputField, responseData[field]['fields'][inputField]);
            })
            inputValues[field] = (field == "thumbnail") ? new File([inputValues[field]], "thumbnail.png", { type: inputValues[field].type }) : inputValues[field]
            dataFile.append("file", inputValues[field]);

            axios
              .post(responseData[field]['url'], dataFile, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Access-Control-Allow-Origin": "*"
                },
              })
              .then((_response) => {
                console.log("response", _response);
                responseCheck[field] = _response
                if (checkResponseFiles()) {
                  setSuccessAddReward("Recompensa adicionada");
                  setInputValues({
                    active: true,
                    auto_claimed: true,
                  });
                  setIsAddingReward(false);
                  alert("Recompensa adicionada");
                  window.location.reload();
                }
              }).catch((error) => {
                setErroAddReward({ error: "Deu erro!" });
                setIsAddingReward(false);
                console.log(error);
              })
          });
        })
        .catch((error) => {
          setErroAddReward({ error: "Deu erro!" });
          setIsAddingReward(false);
          console.log(error);
        });
    });
  };

  const getFieldsByRewardType = (reward, index) => {
    const paternComponent = {
      input: (
        <Input
          className={index % 2 === 0 ? classes.flexRight : classes.flexLeft}
          type={reward.type}
          name={reward.name}
          value={inputValues[reward.name]}
          placeholder={getHeadTableNames(reward.name)}
          onChange={(e) => handleInputChange(e.target.value, reward.name)}
          required={reward.required}
          color="red"
        />
      ),
      textArea: (
        <Input.TextArea
          className={""}
          type={reward.type}
          name="value"
          value={inputValues[reward.name]}
          placeholder={getHeadTableNames(reward.name)}
          required={reward.required}
          onChange={(e) => handleInputChange(e.target.value, reward.name)}
        />
      ),
      autocomplete: (
        <AutoComplete
          className={classes.SelectTypes}
          name={reward.name}
          onChange={(e) => handleInputChange(e, reward.name)}
          options={reward.options}
          mode={reward.mode}
          tokenSeparators={[',']}
          tagRender={tagRender}
          required={reward.required}
          value={inputValues[reward.name]}
        ></AutoComplete>
      ),
      select: (
        <Select
          className={classes.SelectTypes}
          name={reward.name}
          onChange={(e) => handleInputChange(e, reward.name)}
          options={reward.options}
          mode={reward.mode}
          tokenSeparators={[',']}
          tagRender={tagRender}
          required={reward.required}
          value={inputValues[reward.name]}
        ></Select>
      ),
      upload: (
        <Upload
          listType="picture-card"
          onChange={(e) =>
            handleInputChange((e.fileList && e.fileList[0] && e.fileList[0].originFileObj) ? e.fileList[0].originFileObj : undefined, reward.name)
          }
          maxCount={1}
          showUploadList={{
            showPreviewIcon: false,
          }}
          action={(file) => {
            console.log(file);
          }}
          beforeUpload={Upload.LIST_IGNORE}
          accept={reward.accept}
          required={reward.required}
        >
          <div>
            <PlusOutlined />
          </div>
        </Upload>
      ),
      checkbox: (
        <Checkbox
          checked={inputValues[reward.name]}
          onChange={(e) => handleInputChange(e.target.checked, reward.name)}
        ></Checkbox>
      ),
    };
    return paternComponent[reward.typeComponent];
  };

  const _addRewardFrom = () => (
    <div>
        <h4 style={{marginTop: "50px", color: "#0a4148", fontWeight: "bold"}}>ADICIONAR RECOMPENSA</h4>
      <Form
        onSubmit={sendReward}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
        size="large"
        layout="vertical"
        className={classes.AddRewardForm}
      >
        {erroAddReward.hasOwnProperty("error") ? (
          <Tag color="error">{erroAddReward.error}</Tag>
        ) : null}
        {successAddReward ? (
          <Tag color="success">{successAddReward}</Tag>
        ) : null}
        <Space direction="vertical" split={<Divider orientation="left" type="horizontal" ></Divider>}>
          <Row gutter={4}>
            {
              rewardsElements.map((reward, index) => (
                <Col key={index} span={8}>
                  <Form.Item
                  className={classes.FormItem}
                    label={getHeadTableNames(reward.name)}
                    help={
                      erroAddReward.hasOwnProperty(reward.name)
                        ? erroAddReward[reward.name]
                        : null
                    }
                    
                    validateStatus={
                      erroAddReward.hasOwnProperty(reward.name) ? "error" : ""
                    }
                  >
                    {getFieldsByRewardType(reward, index)}
                  </Form.Item>
                </Col>
              ))
            }
          </Row>
          <Permission
            onChange={(value) => handleInputChange(value, "permissions")}
            value={inputValues["permissions"]}
            rewards={rewards}
            label="Permissões"
            description="Regras para listar a recompensa para o usuário."
          ></Permission>
          <Permission
            onChange={(value) => handleInputChange(value, "conditions")}
            value={inputValues["conditions"]}
            label="Condições"
            rewards={rewards}
            description="Regras para o usuário resgatar a recompensa."
          ></Permission>
        </Space>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div className={classes.addRewardArea}>
            <Button
              type="primary"
              onClick={sendReward}
              disabled={isAddingReward}
              >
              {!isAddingReward ? "Adicionar" : "Carregando..." }
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );



  const [addRewardForm, setAddRewardForm] = useState(null);

  useEffect(() => {
    if(rewards!==null){
      setAddRewardForm(_addRewardFrom());
    }
  }, [inputValues, isAddingReward, erroAddReward,rewards]);

  return addRewardForm;
};

export default AddRewards;
