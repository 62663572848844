import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ADMIN_REWARDS, TAG_MAP_RENDER } from "../../constants/api";
import { R_EDIT_REWARDS, R_ADD_REWARDS } from "../../constants/routes";
import { Auth } from "aws-amplify";
import * as classes from "./Recompensas.module.scss";
import Spinner from "../../Components/Spinner";
import { useHistory } from "react-router-dom";
import { Table, Button, Tag, Switch } from "antd";

const Recompensas = () => {
  const [rewards, setRewards] = useState([]);
  const [rewardsTable, setRewardsTable] = useState([]);
  const [isLoadingRewards, setIsLoadingRewards] = useState(false);
  const history = useHistory();
  const isMounted = useRef(true);

  const spinner = <Spinner />;

  useEffect(() => {
    isMounted.current = true;

    if (rewards.length === 0) {
      getRewards();
    }
    if (rewards.length !== 0) {
      setRewardsTable(getRewardsTable(rewards));
    }
    return () => {
      isMounted.current = false;
    };
  }, [rewards]);

  const getRewards = () => {
    setIsLoadingRewards(true);
    Auth.currentAuthenticatedUser().then((user) => {
      axios
        .get(ADMIN_REWARDS, {
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((response) => {
          setIsLoadingRewards(false);
          if (isMounted.current) {
            if (Object.keys(response.data).length > 0) {
              setRewards(response.data);
              localStorage.setItem('rewards', JSON.stringify(response.data));
            } else {
              setRewards({
                error: "Não foi possível trazer as recompensas",
              });
            }
          }
        })
        .catch((error) => {
          setIsLoadingRewards(false);
          if (isMounted.current) {
            setRewards({
              error: String(error),
            });
          }
        });
    });
  };

  const getHeadTableNames = (name) => {
    const names = {
      reward_name: "Nome",
      category: "Categoria",
      reward_buyed_count: "Qtd vezes comprada",
      date_created: "Criado em",
      date_updated: "Modificado em",
      money_price: "Preço (R$)",
      resource: "Recurso",
      slug: "Slug",
      star_price: "Preço (Af$)",
      type: "Tipo",
      active: "Ativo",
      deletar: "Inativar",
      editar: "Editar",
    };

    const resp = name in names ? names[name] : name;

    return resp;
  };
  function uniq_fast(a) {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for(var i = 0; i < len; i++) {
         var item = a[i];
         if(seen[item] !== 1) {
               seen[item] = 1;
               out[j++] = item;
         }
    }
    return out;
}
  const getRewardsTable = (data) => {
    if (data?.error) {
      return <div>Nenhuma recompensa disponível.</div>;
    }

    const columns = Object.keys(data[0]).map((key) => {
      return {
        dataIndex: key,
        title: getHeadTableNames(key),
        filters: uniq_fast([...data.reduce((_acc, _row) => {
          if (key === 'tags'){
            if(_row[key])
             _acc.push(..._row[key].split(','))
          }
          else _acc.push(_row[key])
          return _acc
        }, [])]).map((row) => {
          return { text: row, value: row };
        }),
        filterSearch: true,
        onFilter: (value, record) => {
          return String(record[key])?.includes(value)
        },
        render: (value) => {
          const paternTable = {
            active: () => (
              <Switch disabled={true} defaultChecked={value === "Y"} style={{ color: "red" }}></Switch>
            ),
            tags: () => (
              <>
                {value?.split(",").map((r, i) => (
                  <Tag color={TAG_MAP_RENDER.hasOwnProperty(r) ? TAG_MAP_RENDER[r] : "geekblue"} key={'t' + i}>{r}</Tag>
                ))}
              </>
            ),
          };
          const selectPatern = paternTable.hasOwnProperty(key)
            ? paternTable[key]()
            : value;
          return selectPatern;
        },
      };
    });
    columns.push({
      title: "Editar",
      dataIndex: "",
      key: "x",
      render: (record) => {
        return (
          <>
            <Button
              // type="primary"
              className={classes.EditReward} 
              onClick={() => history.push(R_EDIT_REWARDS(record.id))}
            >
              Editar
            </Button>
          </>
        );
      },
    });
    const newData = data.map((d, i) => {
      d["key"] = i;
      return d;
    });

    return (
      <Table
        style={{fontSize: "14px"}}
        className={classes.RewardTable}
        columns={columns}
        dataSource={newData}
      ></Table>
    );
  };

  return (
    <div>
      <div className={classes.RewardsList}>
        <h2 style={{marginTop: "50px", color: "#0a4148", fontWeight: "bold"}}>RECOMPENSAS</h2>
        <Button className={classes.AddButton} onClick={() => history.push(R_ADD_REWARDS)}>
          Adicionar recompensa
        </Button>
      </div>
        {isLoadingRewards ? spinner : rewardsTable}
    </div>
  );
};

export default Recompensas;
