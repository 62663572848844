import React, { forwardRef, useState, useEffect, useRef } from "react";

import {
    Input,
    Button,
    InputNumber,
    Col,
    Row, Form,
    DatePicker,
    Select,
    List, Card, Space, Divider
} from "antd";
import moment from "moment";


const PermissionModel = forwardRef((props, ref) => {
    const { options,
        dark,
        huge,
        large,
        className = "",
        modalEditIndex = -1,
        onChange = () => { },
        optionsPermissions = {},
        permissionsKey = "",
        tmpRole = {},
        setTmpRole = () => { }
    } = props

    const [mapPermission, setMapPermission] = useState({});
    const [fields, setFields] = useState([])
    const [content, setContent] = useState(null)
    const [state, setState] = useState(-1)
    const [stateModalEdit, setStateModalEdit] = useState("")
    React.useImperativeHandle(ref, () => ({
        setStateModalEdit,
    }));
    // console.log("optionsPermissions", optionsPermissions)
    const blockTypes = [
        "date"
    ] // para futuramente tratar melhor as datas dinamicas das query

    const translateDataTypes = {
        "int": "number",
        "str": "string",
        "date": "date"
    }


    const placeholderPattern = {
        "init": "Insira uma data ou padrão de data Ex: 14/10",
        "end": "Insira uma data ou padrão de data Ex: 14/12",
        "pattern": "Insira o padrão das datas (%d=dia,%w=semana,%m=Mês,%Y=Ano) Ex: %d/%m"
    }
    const initMapValues = {
        "str": "",
        "date": moment().format("YYYY-MM-DD"),
        "int": 0
    }

    const onFieldChange = (e, type, role) => {
        const _tmpRole = tmpRole
        const atribute = {}

        const innerMapValues = {}
        const keyRole = (role === "" || role != e) ? e : role

        if (initMapValues.hasOwnProperty(optionsPermissions[permissionsKey].fields[e]) && optionsPermissions[permissionsKey].options) {
            innerMapValues[type] = e
            innerMapValues["type"] = optionsPermissions[permissionsKey].fields[e]
            innerMapValues["operator"] = ""
            atribute[keyRole] = initMapValues[optionsPermissions[permissionsKey].fields[e]]
        } else {
            innerMapValues[type] = e
            innerMapValues["typeValue"] = optionsPermissions[permissionsKey].fields[e]
            innerMapValues["type"] = (Array.isArray(optionsPermissions[permissionsKey].fields[e])) ? typeof optionsPermissions[permissionsKey].fields[e][0] : optionsPermissions[permissionsKey].fields[e]
            if(innerMapValues["type"]==="object" && optionsPermissions[permissionsKey].fields[e][0].hasOwnProperty("value") && optionsPermissions[permissionsKey].fields[e][0].hasOwnProperty("label")) 
            innerMapValues["type"] = "inner"
            innerMapValues["operator"] = "custom"
            atribute[keyRole] = Array.isArray(innerMapValues["typeValue"]) && innerMapValues["type"] === "object" ? [{ "": "" }] : ""
        }


        if ((role === "" && tmpRole.hasOwnProperty(role)) || role != e) delete tmpRole[role]

        const _mapPermCur = {
            ...mapPermission, ...{
                [keyRole]: {
                    ...(mapPermission[keyRole]) ? mapPermission[keyRole] : {},
                    ...innerMapValues
                }
            }
        }

        // console.log("_mapPermCur", _mapPermCur)
        setMapPermission(_mapPermCur)

        setTmpRole({ ..._tmpRole, ...atribute });
    }

    const onOperatorChange = (value, e, type, role) => {
        const atribute = {}
        const _tmpRole = tmpRole
        let keyRole = (role === "") ? e : role
        const innerMapValues = {}

        delete _tmpRole[keyRole]
        const _mapPermCur = (mapPermission[keyRole]) ? mapPermission[keyRole] : {}
        if (["not_equal", "not_in"].indexOf(value) > -1 && !keyRole.includes("!")) {
            delete _mapPermCur[keyRole]
            keyRole = "!" + keyRole
        }
        if (["not_equal", "not_in"].indexOf(value) == -1 && keyRole.includes("!")) {
            delete _mapPermCur[keyRole]
            keyRole =  keyRole.replace("!" ,"")
        }

        innerMapValues[type] = value;
        innerMapValues["typeValue"] = e;


        atribute[keyRole] = ["list"].indexOf(e) > -1 ? [] : e
        const _mapPermission = {
            ...mapPermission, ...{
                [keyRole]: {
                    ..._mapPermCur,
                    ...innerMapValues
                }
            }
        }
        setMapPermission(_mapPermission)
        // console.log("mapPermission", _mapPermission);

        setTmpRole({ ..._tmpRole, ...atribute });
    }

    const onInputValueChange = (e, type, role, index = null, subrole = null) => {
        // console.log("onInputValueChange", e, type, role, index, subrole)
        const atribute = {}
        const keyRole = (role === "") ? e : role
        // console.log(e, type, role)
        const _mapPermCur = (mapPermission[keyRole]) ? mapPermission[keyRole] : {};
        if (index === null) {
            atribute[keyRole] = e
            _mapPermCur[type] = e

        } else {
            atribute[keyRole] = (tmpRole[keyRole]) ? tmpRole[keyRole] : [{}]
            if (subrole === null) {
                const cur_index_att = (atribute[keyRole][index]) ? atribute[keyRole][index] : {}
                atribute[keyRole][index] = { ...cur_index_att, [e]: "" }
                delete atribute[keyRole][index][""]
            } else {

                atribute[keyRole].splice(index, 1, { ...atribute[keyRole][index], [subrole]: e })
            }
            // console.log('atribute[keyRole]', atribute[keyRole])
        }
        const _mapPermission = { ...mapPermission, [keyRole]: _mapPermCur }
        setMapPermission(_mapPermission)
        // console.log("mapPermission", _mapPermission);
        setTmpRole({ ...tmpRole, ...atribute });
    }

    const mapFieldForType = {
        "number": ({ tmpRole, placeholderInput, role }) => {
            // console.log("number", tmpRole, role, tmpRole[role])
            return (
                <Col xs="6">
                    <InputNumber
                        size="large"
                        value={(tmpRole[role] == "input") ? "" : tmpRole[role]}
                        placeholder={placeholderInput}
                        onChange={(e) => { onInputValueChange(e, "inputValue", role) }}
                        required
                    ></InputNumber>
                </Col>
            )
        },
        "date": ({ tmpRole, placeholderInput, role }) => {
            // console.log("string", tmpRole, role, tmpRole[role])
            return (
                <Col xs="6">
                    <DatePicker
                        size="large"
                        value={(tmpRole[role] == "input") ? "" : tmpRole[role]}
                        placeholder={placeholderInput}
                        onChange={(e) => { onInputValueChange(e.target.value, "inputValue", role) }}
                        required
                    ></DatePicker>
                </Col>
            )
        },
        "string": ({ tmpRole, placeholderInput, role }) => {
            // console.log("string", tmpRole, role, tmpRole[role])
            return (
                <Col xs="6">
                    <Input
                        size="large"
                        value={(tmpRole[role] == "input") ? "" : tmpRole[role]}
                        placeholder={placeholderInput}
                        onChange={(e) => { onInputValueChange(e.target.value, "inputValue", role) }}
                        required
                    ></Input>
                </Col>
            )
        },
        "array": ({ tmpRole, placeholderInput, role }) => {
            // console.log("array", tmpRole, role, tmpRole[role])
            // console.log("mapPermission[role]['typeValue']", mapPermission[role]['typeValue'])
            return (
                <Col xs="6">
                    <Select
                        style={{ width: 280 }}
                        size="large"
                        value={(tmpRole[role] !== "") ? tmpRole[role] : undefined}
                        options={(mapPermission[role]['type']==='inner')?mapPermission[role]['typeValue']:mapPermission[role]['typeValue'].map(v => { return { "value": v, "label": v } })}
                        placeholder={placeholderInput}
                        onChange={(e) => { onInputValueChange(e, "inputValue", role) }}
                        required
                    ></Select>
                </Col>
            )
        },
        "list": ({ tmpRole, placeholderInput, role }) => {
            // console.log("list", tmpRole, role, tmpRole[role])
            // console.log("mapPermission[role]['typeValue']", mapPermission[role]['typeValue'])
            const keyFields = Object.keys(mapPermission[role]['typeValue'][0])
            return (
                <Col span={24} >
                    {
                        tmpRole[role].map((_r, i, all) => {
                            const currentFilters = keyFields.filter(v => !_r.hasOwnProperty(v))
                            return <Row className="gutter-row" justify="center">
                                <Divider orientation="left">{(i + 1) + "º)"}
                                    <Button
                                        disabled={currentFilters.length == 0}

                                        type="primary" onClick={() => {
                                            const _tmpRole = tmpRole
                                            tmpRole[role][i] = { ...tmpRole[role][i], "": "" }
                                            setTmpRole({ ..._tmpRole })
                                        }}>Add Prop</Button>
                                </Divider>

                                {Object.keys(_r).map((_rk, _ik) => {
                                    return <Col span={16} key={"c" + _ik}>
                                        <Row className="gutter-row" >
                                            <Input
                                                addonBefore={
                                                    <Select
                                                        size="large"
                                                        value={(_rk !== "") ? _rk : undefined}
                                                        options={currentFilters.map(v => { return { "value": v, "label": v } })}
                                                        placeholder={"Selecione uma opção"}
                                                        onChange={(e) => { onInputValueChange(e, "inputValue", role, i) }}
                                                        required />
                                                }
                                                size="large"
                                                value={(_r[_rk] == "input") ? "" : _r[_rk]}
                                                placeholder={placeholderPattern.hasOwnProperty(_rk) ? placeholderPattern[_rk] : ""}
                                                onChange={(e) => { onInputValueChange(e.target.value, "inputValue", role, i, _rk) }}
                                                required
                                            />
                                        </Row>
                                    </Col>
                                }
                                )}

                            </Row>
                        })
                    }
                    <Button type="primary" onClick={() => {
                        const _tmpRole = tmpRole
                        tmpRole[role].push({ "": "" }); setTmpRole({ ..._tmpRole })
                    }}>Add Role</Button>
                    {/* {Object.keys(mapPermission[role]['typeValue']).map(key => {
                        return mapFieldForType[translateDataTypes[mapPermission[role]['typeValue'][key]] ]({ tmpRole, placeholderInput, role })
                    })} */}
                </Col>
            )
        },
        "object": ({ tmpRole, placeholderInput, role }) => {
            // console.log("object", tmpRole, role, tmpRole[role])
            // console.log("mapPermission[role]['type']", mapPermission[role]['type'])
            return (
                <Col xs="6">
                    <Select
                        mode="tags"
                        style={{ width: 280 }}
                        size="large"
                        value={(tmpRole[role] == "input") ? "" : tmpRole[role].filter(v => v !== "" && ((mapPermission[role]['type'] === 'int' && !isNaN(v)) || (mapPermission[role]['type'] === 'str')))}
                        tokenSeparators={[',']}
                        placeholder={placeholderInput}
                        onChange={(e) => { onInputValueChange(e, "inputValue", role) }}
                        required
                    ></Select>
                </Col>
            )
        }
    }

    const removeRole = (role, i) => {
        // console.log("removeRole", role, i)
        const _tmpRole = tmpRole
        delete _tmpRole[role]
        setTmpRole(_tmpRole)
        const _mapPermCur = mapPermission
        delete _mapPermCur[role]
        setMapPermission(_mapPermCur)
        setState(state + 1)
    }



    useEffect(() => {
        setFields(
            Object.keys(optionsPermissions[permissionsKey].fields)
                .filter(
                    f => !tmpRole.hasOwnProperty(f) && !tmpRole.hasOwnProperty("!" + f) && blockTypes.indexOf(optionsPermissions[permissionsKey].fields[f]) === -1
                )
        )
        // console.log("fields", fields.length, fields);
    }, [optionsPermissions, tmpRole])

    useEffect(() => {
        // console.log("stateModalEdit", stateModalEdit)
        // console.log("permissionsKey+modalEditIndex", permissionsKey + modalEditIndex)
        if (tmpRole == { "": "" }) {
            setMapPermission({})
        } else if (tmpRole && modalEditIndex > -1 && stateModalEdit != permissionsKey + modalEditIndex) {
            setStateModalEdit(permissionsKey + modalEditIndex)
            // console.log("tmpRole", tmpRole)
            // console.log("mapPermission", mapPermission)
            const innerMapValues = {

            }

            Object.keys(tmpRole).forEach(key => {
                let curKey = key
                innerMapValues[key] = {
                    "field": key,
                    "operator": (Array.isArray(tmpRole[key]) ? "in" : "equals"),
                    "typeValue": (Array.isArray(tmpRole[key]) ? "list" : "input"),
                    "inputValue": tmpRole[key]
                }
                if (key.includes("!")) {
                    innerMapValues[key]["operator"] = "not_" + innerMapValues[key]["operator"]
                    curKey = key.replace("!", "")
                }


                if (initMapValues.hasOwnProperty(optionsPermissions[permissionsKey].fields[curKey]) && optionsPermissions[permissionsKey].options) {
                    innerMapValues[key]["type"] = optionsPermissions[permissionsKey].fields[curKey]
                } else {
                    innerMapValues[key]["typeValue"] = optionsPermissions[permissionsKey].fields[curKey]
                    innerMapValues[key]["type"] = (Array.isArray(optionsPermissions[permissionsKey].fields[curKey])) ? typeof optionsPermissions[permissionsKey].fields[curKey][0] : optionsPermissions[permissionsKey].fields[curKey]
                    innerMapValues[key]["operator"] = "custom"
                }
            })
            // console.log("setMapPermission", innerMapValues)
            setMapPermission(innerMapValues)
        }
        setContent(<List >
            {
                Object.keys(tmpRole).map((role, i) => {

                    return (
                        <Row gutter={8} key={'row' + i}>
                            <Col xs="6">
                                {(i > 0) ? " && " : ""}
                                <Button onClick={() => { removeRole(role, i) }}>x</Button>
                                <Select style={{ width: 280 }} size="large" placeholder={optionsPermissions[permissionsKey].placeholderField} value={(role === "") ? undefined : role} onChange={(e) => { onFieldChange(e, "field", role) }} options={fields.map(keyField => { return { "value": keyField, "label": keyField } })}></Select>
                            </Col>
                            {
                                role != "" && mapPermission[role]?.hasOwnProperty("operator") && mapPermission[role]['operator'] != "custom" &&
                                (
                                    <Col xs="6">
                                        <Select
                                            size="large"
                                            style={{ width: 180 }}
                                            value={(mapPermission.hasOwnProperty(role) && mapPermission[role].hasOwnProperty("operator")) && mapPermission[role]["operator"] !== "" ? mapPermission[role]["operator"] : undefined}
                                            placeholder={optionsPermissions[permissionsKey].placeholderOperator}
                                            onChange={(e) => { onOperatorChange(e, optionsPermissions[permissionsKey].options[e], "operator", role) }}
                                            options={Object.keys(optionsPermissions[permissionsKey].options).map(keyField => { return { "value": keyField, "label": keyField } })}
                                        ></Select>
                                    </Col>
                                )
                            }
                            {
                                role != "" &&
                                mapPermission.hasOwnProperty(role) && mapPermission[role].hasOwnProperty("operator") &&
                                ["have", "not_have", ""].indexOf(mapPermission[role]["operator"]) === -1 &&
                                (
                                    (
                                        (mapPermission[role]['type'] === 'str' || (mapPermission[role]['typeValue'] && mapPermission[role]['typeValue'] === 'list')) &&
                                        mapFieldForType.hasOwnProperty(typeof tmpRole[role]) &&
                                        mapFieldForType[typeof tmpRole[role]]({ tmpRole, placeholderInput: optionsPermissions[permissionsKey].placeholderInput, role })
                                    )
                                    ||
                                    (
                                        (mapPermission[role]['typeValue'] && Array.isArray(mapPermission[role]['typeValue'])) && ['string','inner'].indexOf(mapPermission[role]['type']) >-1  &&
                                        mapFieldForType["array"]({ tmpRole, placeholderInput: optionsPermissions[permissionsKey].placeholderInput, role })
                                    )
                                    ||
                                    (
                                        (mapPermission[role]['typeValue'] && Array.isArray(mapPermission[role]['typeValue'])) && mapPermission[role]['type'] === 'object' &&
                                        mapFieldForType["list"]({ tmpRole, placeholderInput: optionsPermissions[permissionsKey].placeholderInput, role })
                                    )
                                    ||
                                    (
                                        mapPermission[role]['type'] === 'date' &&
                                        moment(tmpRole[role]).isValid() &&
                                        mapFieldForType["date"]({ tmpRole, placeholderInput: optionsPermissions[permissionsKey].placeholderInput, role })
                                    )
                                    ||
                                    (
                                        mapPermission[role]['type'] === 'int' &&
                                        mapFieldForType["number"]({ tmpRole, placeholderInput: optionsPermissions[permissionsKey].placeholderInput, role })
                                    )
                                )
                            }
                        </Row>
                    )
                })
            }
            <Row>
                <Col xs="18">
                    <Button disabled={tmpRole.hasOwnProperty("") || fields.length === 0} onClick={() => { onFieldChange("", "field", "") }}>+</Button>
                </Col>
            </Row>
        </List>)
    }, [tmpRole, optionsPermissions, permissionsKey, mapPermission, fields, state, modalEditIndex])



    return <>
        {content}
    </>;
});

export default PermissionModel