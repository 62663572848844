import React, { useRef, useEffect, useState } from "react";
// import ReactDOMServer from "react-dom/server";
import classes from "./ModalSuperAdmin.module.scss";

const ModalSuperAdmin = ({ setShowModalAdmin, getCompany, adminPosition }) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;

  const [openState, setModal] = useState(classes.WrapModalhide);

  useEffect(() => {
    setModal(classes.WrapModal);
  }, []);

  content_modal = (
    <div className={openState} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setModal(classes.WrapModalhide);
                setShowModalAdmin(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>
              ESSES SÃO OS SUPER ADMINS CADASTRADOS NA EMPRESA:
            </div>

            <div>
              <div className={classes.ModalLabel}>
                <div orientation="center" className={classes.ModalUserEmail}>
                  {getCompany[adminPosition].super_admin.length < 1 ? (
                    <div>o admin irá aparecer ao acessar o audiofoco</div>
                  ) : (
                    getCompany[adminPosition].super_admin.map((admin) => (
                      <div>{admin}</div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className={classes.OptionsYesOrNo}>
              <button
                tabIndex="0"
                onClick={() => {
                  setModal(classes.WrapModalhide);
                  setShowModalAdmin(false);
                }}
                className={classes.CloseButtonText}
              >
                FECHAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalSuperAdmin;
