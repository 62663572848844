import React, { useRef, useEffect, useState } from "react";
// import ReactDOMServer from "react-dom/server";
import classes from "./ModalEditPlan.module.scss";
import { Auth } from "aws-amplify";

import { Row, Col, Divider } from "antd";
// import Select from "../../Select/Select";
import axios from "axios";
import { ADMIN_EDIT_PLAN_FROM_USER } from "../../../constants/api";
// import { DatePicker, Space } from "antd";
import moment from "moment";
import InputMask from "react-input-mask";

import CurrencyInput from "../../Inputs/CurrencyInput/CurrencyInput";
import Spinner from "../../Spinner";

// import { ADMIN_GET_EMAILS } from "../../../constants/api";

const ModalEditPlan = ({
  plan,
  related,
  typeModal,
  setShowModalCancelRecurrence,

  users,
  setShowModal,
  setRerenderModal,
  forceUpdate,
  setForceUpdate,
}) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;

  let valueDigited = "";

  // let next_charge = "";

  const [loginInfo, setLoginInfo] = useState();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [relatedObject, setRelatedObject] = useState({});

  const [error, setError] = useState("");
  const [openState, setModal] = useState(classes.WrapModalhide);
  const [canAdd, setCanAdd] = useState(false);
  const [ProductValueDigited, setProductValueDigited] = useState("");
  const [PlanAddValueDigited, setPlanAddValueDigited] = useState("");
  const [PaymentMethodValueDigited, setPaymentMethodValueDigited] =
    useState("");
  const [NextChargeValueDigited, setNextChargeValueDigited] = useState("");
  const [NextCharge, setNextCharge] = useState("");
  const [ObservationsValueDigited, setObservationsValueDigited] = useState("");
  const [PaymentPriceValueDigited, setPaymentPriceValueDigited] = useState("");
  const [PlansIds, setPlansIds] = useState([]);
  const [OrderID, setOrderID] = useState("");

  useEffect(() => {
    setModal(classes.WrapModal);
    setPaymentPriceValueDigited(
      "payment_amount" in plan && plan.payment_amount
        ? plan.payment_amount
        : related.reduce((finalPrice, item) => {
            return finalPrice + item.price;
          }, 0)
    );
    setNextCharge(moment(plan.next_charge, "YYYY-MM-DD").format("DD/MM/YYYY"));
    setNextChargeValueDigited(
      moment(plan.next_charge, "YYYY-MM-DD").format("YYYY-MM-DD")
    );

    setPaymentMethodValueDigited(plan.payment_method);
    setPlansIds(related.map((item) => item.id));

    setObservationsValueDigited(plan.observations);
  }, [plan, related]);

  const addPlan = () => {
    Auth.currentAuthenticatedUser().then((u) => {
      let data = {
        order_id: plan.order_id,
        plans: PlansIds,
        payment_method: PaymentMethodValueDigited,
        next_charge: NextChargeValueDigited,
        value: PaymentPriceValueDigited,
        observation: ObservationsValueDigited,
        orderID: OrderID,
        sub: plan.sub,
      };

      // console.log(data)

      axios
        .post(ADMIN_EDIT_PLAN_FROM_USER, data, {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((resp) => {
          setLoading(false);
          setForceUpdate(forceUpdate + 1);
          setShowModal(false);
        })
        .catch((e) => {
          setLoading(false);
          setError("Usuário não tem permissão ou já possui o produto.");
        });
    });
  };
  useEffect(() => {
    validateEditPlan();

    // setRelatedObject(
    //   related.reduce((acc, item) => {
    //     if (item.name in acc) acc[item.name] = acc[item.name] + 1;
    //     else acc[item.name] = 1;
    //     return acc;
    //   }, {})
    // );
  }, [
    ProductValueDigited,
    PlanAddValueDigited,
    PaymentMethodValueDigited,
    NextChargeValueDigited,
    PlansIds,
    validateEditPlan,
  ]);

  const validateEditPlan = () => {
    let data = {
      // "order_id": plan.order_id,
      plans: PlansIds.length,
      payment_method: PaymentMethodValueDigited,
      next_charge: NextChargeValueDigited,
      // "value": PaymentPriceValueDigited,
      //"observation": ObservationsValueDigited,
      // "orderID": OrderID,
      sub: plan.sub,
    };
    let _canAdd = true;
    if (data["plans"] == 0) {
      _canAdd = false;
    }
    for (let key in data) {
      if (!data[key] || data[key] == "Invalid date") {
        _canAdd = false;
      }
    }
    setCanAdd(_canAdd);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setLoginInfo(user.attributes.email);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const mapPaymentMethod = {
    creditcard: "Cartão de Crédito",
    cortesia: "Cortesia",
    pix: "Pix",
    transferencia: "Transferência",
    boleto: "Boleto",
  };

  let SelectPaymentMethod = (
    <select
      className={classes.Dropdown}
      onChange={(e) => {
        let valueSelect = e.target.value;
        setPaymentMethodValueDigited(valueSelect);
      }}
    >
      {Object.keys(mapPaymentMethod).map((payment_method) => {
        return (
          <option
            className={classes.Option}
            value={payment_method}
            selected={
              payment_method === PaymentMethodValueDigited ? "selected" : ""
            }
          >
            {mapPaymentMethod[payment_method]}
          </option>
        );
      })}
    </select>
  );

  //Content
  content_modal = (
    <div className={openState} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setModal(classes.WrapModalhide);
                setShowModal(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>Editar / estender plano</div>
            {error != "" && <div className={classes.ErrorMessage}>{error}</div>}

            <div className={classes.ModalLabel}>
              <div className={classes.dados}>
                Nome: {users?.given_name} <br />
                E-mail: {users?.email} <br />
                Order ID: {plan.orderID} <br />
              </div>

              <div className={classes.listPlans}>
                {related.map((plan_related) => {
                  return (
                    <Divider orientation="left" className={classes.ModalPlans}>
                      <input
                        type="checkbox"
                        checked={
                          PlansIds.indexOf(plan_related.id) === -1
                            ? ""
                            : "checked"
                        }
                        onChange={(e) => {
                          let planCheck = e.target.value;
                          const indexPlan = PlansIds.indexOf(plan_related.id);
                          if (indexPlan === -1) {
                            setPlansIds([...PlansIds, plan_related.id]);
                          } else {
                            setPlansIds([
                              ...PlansIds.filter((item, i) => indexPlan !== i),
                            ]);
                          }
                        }}
                      />

                      <Divider type="vertical"></Divider>
                      {plan_related.name}
                    </Divider>
                  );
                })}
              </div>

              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={12}>
                  <div>Método de Pagamento*</div>
                  {SelectPaymentMethod}

                  <div className={classes.observation}>
                    <div>Observação</div>
                    <input
                      className={classes.InputModal}
                      value={ObservationsValueDigited}
                      type="text"
                      onChange={(e) => {
                        let valueDigited = e.target.value;
                        setObservationsValueDigited(valueDigited);
                      }}
                    />
                  </div>
                  <div className={classes.orderId}>
                    <div>OrderID/ID de Rastreamento* </div>
                    <i>Se não preenchido, não irá inserir uma compra</i>
                    <input
                      className={classes.InputModal}
                      type="text"
                      onChange={(e) => {
                        let valueDigited = e.target.value;
                        setOrderID(valueDigited);
                      }}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div>Data de Vencimento*</div>
                  <InputMask
                    tabindex="6"
                    required
                    maskChar={null}
                    className={classes.InputModal}
                    placeholder={moment(plan.next_charge).format("DD/MM/YYYY")}
                    value={NextCharge}
                    mask="99/99/9999"
                    onChange={(e) => {
                      setNextCharge(e.target.value);
                      if (e.target.value.length === 10) {
                        let valueDigited = moment(e.target.value, "DD/MM/YYYY");
                        if (!valueDigited.isBefore(moment()))
                          setNextChargeValueDigited(
                            valueDigited.format("YYYY-MM-DD")
                          );
                        else setNextChargeValueDigited("");
                      } else {
                        setNextChargeValueDigited("");
                      }
                    }}
                  />

                  <div className={classes.valor}>
                    <div>Valor*</div>
                    <i>Valor total do pedido</i>
                    <CurrencyInput
                      className={classes.InputModal}
                      placeholder={"R$ "}
                      value={("R$" + PaymentPriceValueDigited).replaceAll(
                        ".",
                        ","
                      )}
                      tabindex="5"
                      type="text"
                      onChange={(e) => {
                        valueDigited = e.target.value
                          .replaceAll("R$ ", "")
                          .replaceAll(",", ".");
                        setPaymentPriceValueDigited(valueDigited);
                      }}
                    />
                  </div>
                </Col>
                <Row></Row>
              </Row>
            </div>
            {/* </div> */}

            <button
              tabIndex="0"
              disabled={loading ? loading : !canAdd}
              onClick={() => {
                addPlan();
                setLoading(true);
              }}
              className={classes.CloseButtonText}
            >
              Salvar alterações
            </button>
            {loading && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalEditPlan;
