//environ
const GET_PROD_OR_DEV = "prod";

//config
const PROD_API = "https://ldoatul0dk.execute-api.us-east-1.amazonaws.com/v1";
const DEV_API = "https://kx1m9os5f6.execute-api.us-east-1.amazonaws.com/dev1";

const PROD_API_AFINANDO =
  "https://mcqj5lejdg.execute-api.us-east-1.amazonaws.com/v1";
const DEV_API_AFINANDO =
  "https://k9357p16ih.execute-api.us-east-1.amazonaws.com/next-dev-2";

const PROD_URL_FUNCTION =
  "https://32xrnacb5ahdyd2wsb6s7nxwiq0huqkm.lambda-url.us-east-1.on.aws/";

const DEV_URL_FUNCTION =
  "https://kx1m9os5f6.execute-api.us-east-1.amazonaws.com/dev1/afinando/user-search";

const PLUS_PROD_API = "https://afinandoplus.probrain.com.br/modules";
const PLUS_DEV_API = "https://hafinandoplus.probrain.com.br/modules";

// plus
export const PLUS_API =
  GET_PROD_OR_DEV === "prod" ? PLUS_PROD_API : PLUS_DEV_API;

// geral
const API = GET_PROD_OR_DEV === "prod" ? PROD_API : DEV_API;
const API_NEXT =
  GET_PROD_OR_DEV === "prod" ? PROD_API_AFINANDO : DEV_API_AFINANDO;
const API_WITH_URL_FUNCTION =
  GET_PROD_OR_DEV === "prod" ? PROD_URL_FUNCTION : DEV_URL_FUNCTION;

//endpoints
export const ADMIN_CHANGE_PLAN = API + "/afinando/plans/change-plan";
export const ADMIN_AMOUNTS_BY_NAME =
  API +
  "/afinando/plans/amounts/by-name?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS";
export const ADMIN_AOC_CONFIRM = API + "/afinando/aoc/confirm";
export const ADMIN_REMOVE_COGNITO = API + "/afinando/users/remove-cognito";
export const ADMIN_REMOVE_PAID_PLAN = API + "/afinando/plans/remove-paid-plan";
export const ADMIN_CREATE_COMPANY = API + "/audiofoco/registrations";
export const ADMIN_REMOVE_USER = API + "/afinando/remove-cognito";
export const ADMIN_GET_COMPANIES = API + "/audiofoco/companies";
export const ADMIN_REMOVE_CREDITCARD_FROM_PLAN =
  API + "/afinando/plans/remove-creditcard-from-plan";
export const ADMIN_CANCEL_RECURRENCE =
  API + "/afinando/plans/cancel-recurrence";
export const ADMIN_CANCEL_PLAN = API + "/afinando/plans/cancel-plan";
export const ADMIN_ADD_PLAN_FROM_USER = API + "/afinando/plans/add-plan";
export const ADMIN_EDIT_PLAN_FROM_USER = API + "/afinando/plans/edit-plan";
export const ADMIN_CANCEL_PLAN_INFO = API + "/afinando/users/info";
export const ADMIN_CANCEL_PLAN_BY_DATE = (startDateByDate, endDateByDate) =>
  API +
  `/afinando/users/info?start_date=${startDateByDate}&end_date=${endDateByDate}`;
export const ADMIN_CANCEL_PLAN_BY_MONTH = (
  dateByYear,
  dateByMonth,
) =>
  API +
  `/afinando/users/info?start_date=${dateByYear}-${dateByMonth}&end_date=${dateByYear}-${dateByMonth}`;

export const ADMIN_GET_PLANS =
  API +
  "/afinando/plans?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS";
export const ADMIN_GET_USERS =
  API +
  "/afinando/users?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS";
export const ADMIN_SEARCH_BY_ANY = (search) => {
  return search
    ? API_WITH_URL_FUNCTION +
        "?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS&search=" +
        search
    : API_WITH_URL_FUNCTION +
        "?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS";
};

export const ADMIN_GET_USERS_DATA = (email) => {
  return (
    API +
    "/afinando/plans/user-data?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS&email=" +
    email
  );
};
export const ADMIN_GET_COMPANIES_SEARCH = (emailOrCompany) => {
  return emailOrCompany
    ? API + "/audiofoco/companies/" + emailOrCompany
    : API + "/audiofoco/companies/";
};
export const ADMIN_GET_AUDIOFOCO_USERS = (userUid = "", companyUid = "") => {
  if (userUid && companyUid)
    return (
      API +
      "/audiofoco/user/?user_registry=" +
      userUid +
      "&company_registry=" +
      companyUid
    );
  if (companyUid)
    return API + "/audiofoco/user/?company_registry=" + companyUid;
};
export const ADMIN_AUDIOFOCO_END_JOURNEY =
  API + "/audiofoco/user/close-journey";
export const ADMIN_AUDIOFOCO_ADD_JOURNEY = API + "/audiofoco/add-journey";
export const ADMIN_REWARDS = API_NEXT + "/rewards/admin";
export const ADMIN_GET_EMAILS = (parent_sub) =>
  API +
  "/afinando/users/emails?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS&parent_sub=" +
  parent_sub;

export const ADMIN_GET_ALL_PIX = (dataToSearch, cpfToFind, txid) => {
  return dataToSearch && !cpfToFind && !txid
    ? API +
        "/next-dev-get-all-pix?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS" +
        "&data=" +
        dataToSearch
    : cpfToFind && dataToSearch
    ? API +
      "/next-dev-get-all-pix?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS" +
      "&data=" +
      dataToSearch +
      "&cpf=" +
      cpfToFind
    : API +
      "/next-dev-get-all-pix?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS" +
      "&txid=" +
      txid;
};

const API_AMMOUNT =
  GET_PROD_OR_DEV === "prod"
    ? "https://ty4zhw4ydfancxax4c7pcdmjaa0ydfpg.lambda-url.us-east-1.on.aws/"
    : API + "/afinando/plans/amounts/";
const API_CSV =
  GET_PROD_OR_DEV === "prod"
    ? "https://kkja6ya7jxkzbnhukco75kc2hq0srdyf.lambda-url.us-east-1.on.aws/"
    : API + "/afinando/plans/overview/";
export const ADMIN_GET_PLANS_AMOUNTS = (date_start, date_end) => {
  return date_start || date_end
    ? API_AMMOUNT +
        "?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS" +
        (date_start ? "&date_start=" + date_start : "") +
        (date_end ? "&date_end=" + date_end : "")
    : API_AMMOUNT;
};
export const ADMIN_GET_CSV_OVERVIEW = (date_start, date_end) => {
  return date_start || date_end
    ? API_CSV +
        "?key=Ns5z3yV7pDo9EhveE5VDyqXPNpBoeLWKoRynAjMhJMjR7C2uVS" +
        (date_start ? "&date_start=" + date_start : "") +
        (date_end ? "&date_end=" + date_end : "")
    : API_CSV;
};

export const TAG_MAP_RENDER = {
  "shadow":"purple",
  "new":"green",
  "dailyReward":"gold"
}


export const ADMIN_UPDATE_PLUS_USER = "/update_user_afinando.php";
export const ADMIN_CHECK_PLUS_USER = "/login.php";

export const AFINANDO_STORE = GET_PROD_OR_DEV === "dev" ? "https://afinando-store-dev.s3.amazonaws.com" : "https://afinando-store-prod.s3.amazonaws.com";