import React, { useRef } from "react";
import classes from "./ModalCompanyRegistered.module.scss";

const ModalCompanyRegistered = ({
  setShowModalCompanyAdded,
  setUpdateCompany,
}) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;

  content_modal = (
    <div className={classes.WrapModal} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setShowModalCompanyAdded(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>EMPRESA CADASTRADA COM SUCESSO!</div>
            <div className={classes.OptionsYesOrNo}>
              <button
                onClick={() => {
                  window.location.reload();
                  setShowModalCompanyAdded(false);
                  setUpdateCompany(true);
                }}
                className={classes.CloseButtonText}
              >
                FECHAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalCompanyRegistered;
