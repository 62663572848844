import React, { useRef, useEffect, useState } from "react";
import classes from "./ModalCancelRecurrence.module.scss";
import { Auth } from "aws-amplify";

import { Divider } from "antd";
import axios from "axios";
import {
  ADMIN_CANCEL_RECURRENCE,
  ADMIN_CANCEL_PLAN,
} from "../../../constants/api";
import Spinner from "../../Spinner";

const ModalCancelRecurrence = ({
  plan,
  related,
  typeModal,
  setShowModalCancelRecurrence,
  setForceUpdate,
  forceUpdate,
}) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;
  const map_type_text = { recurrence: "Recorrência", plan: "Plano" };
  const [loading, setLoading] = useState(false);
  // const [loginInfo, setLoginInfo] = useState();
  const [relatedObject, setRelatedObject] = useState({});
  const [openState, setModal] = useState(classes.WrapModalhide);

  useEffect(() => {
    setModal(classes.WrapModal);
  }, []);

  const cancelRecurrency = () => {
    Auth.currentAuthenticatedUser().then((u) => {
      let data = {
        recurrence: {
          product_id: plan.product_id,
          plan_id: plan.id,
          user_sub: plan.sub,
        },
        plan: {
          product_id: plan.product_id,
          plans_id: [plan.id],
          user_sub: plan.sub,
        }
      };
      axios
        .post(
          typeModal === "recurrence"
            ? ADMIN_CANCEL_RECURRENCE
            : ADMIN_CANCEL_PLAN,
          data[typeModal],
          {
            headers: {
              Authorization: Auth.user.signInUserSession.idToken.jwtToken,
            },
          }
        )
        .then((resp) => {
          console.log(resp);
          setLoading(false);
          setForceUpdate(forceUpdate + 1);
          setShowModalCancelRecurrence(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setForceUpdate(forceUpdate + 1);
          setShowModalCancelRecurrence(false);
        });
    });
  };

  useEffect(() => {
    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     console.log(user.attributes.email);
    //     // setLoginInfo(user.attributes.email);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    setRelatedObject(
      related.reduce((acc, item) => {
        if (item.name in acc) acc[item.name] = acc[item.name] + 1;
        else acc[item.name] = 1;
        return acc;
      }, {})
    );
  }, []);

  console.log(plan);
  console.log(related);
  console.log(relatedObject);

  // const PlanAddDependent =
  //   users[0].user_plan?.product_id === "2"
  //     ? users[0].filter(() => return users[0].user_plan.parent_id)
  //     : users[0].user_plan?.product_id === "5" ? "+ 10 Usuarios" : "Sem Adicionais Vinculados"

  //Content
  content_modal = (
    <div className={openState} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setModal(classes.WrapModalhide);
                setShowModalCancelRecurrence(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>
              Cancelamento de {map_type_text[typeModal]}!
            </div>
            <div>
              <div className={classes.ModalLabel}>
                <div>
                  {typeModal === "recurrence"
                    ? "Será Cancelado a Recorrência do Seguinte Plano"
                    : "Será Cancelado o Seguinte Plano"}
                </div>
                <Divider
                  orientation="center"
                  className={classes.ModalUserEmail}
                >
                  {plan?.application_name + ": " + plan?.name}
                </Divider>
              </div>
              {Object.keys(relatedObject).length > 0 && (
                <div className={classes.ModalLabel}>
                  <div>
                    Será Cancelada a Recorrência dos Seguinte Adicionais Filhos:
                  </div>
                  <Divider
                    orientation="center"
                    className={classes.ModalUserEmail}
                  >
                    {Object.keys(relatedObject).length > 0 &&
                      Object.keys(relatedObject).map((key, i) => {
                        return (
                          (i > 0 ? " | " : "") + relatedObject[key] + "x " + key
                        );
                      })}
                  </Divider>
                </div>
              )}
              {loading && <Spinner></Spinner>}
            </div>
            <button
              tabIndex="0"
              onClick={() => {
                cancelRecurrency();
                setLoading(true);
              }}
              className={classes.CloseButtonText}
            >
              Cancelar {map_type_text[typeModal]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalCancelRecurrence;
