import React from 'react'
import {/*  Statistic,  Col,Descriptions, */Card, Divider,Tooltip } from 'antd';
// import { PieChartOutlined, UserOutlined, UserSwitchOutlined, TeamOutlined } from '@ant-design/icons';

// const PLANS = {
//     'plans_amt': {'title': 'Quantidade Usuários','color':'#662d91', 'icon_card': <PieChartOutlined />, 'suffix': false },
//     'plans_paid_amt': { 'title': 'Quantidade Usuários Pago', 'color': '#3f8600', 'icon_card': <PieChartOutlined /> ,'suffix': true},
//     'plans_free_amt': { 'title':'Quantidade Usuários Free', 'color': '#d3d3d3', 'icon_card': <PieChartOutlined />,'suffix': true },
//     'plans_pro_amt': { 'title': 'Quantidade Usuários Pro', 'color': '#662d91', 'icon_card': <UserSwitchOutlined />,'suffix': true},
//     'plans_pro_amt_paid': {'title':'Quantidade Usuários Pro Pago', 'color': '#3f8600', 'icon_card': <UserSwitchOutlined />,'suffix': true},
//     'plans_pro_amt_free': {'title':'Quantidade Usuários Pro Free', 'color': '#d3d3d3', 'icon_card': <UserSwitchOutlined />,'suffix': true},
//     'plans_sin_amt': {'title': 'Quantidade Usuários Ind', 'color': '#662d91', 'icon_card': <UserOutlined />,'suffix': true},
//     'plans_sin_amt_paid': {'title':'Quantidade Usuários Ind Pago', 'color': '#3f8600', 'icon_card': <UserOutlined />,'suffix': true},
//     'plans_sin_amt_free': {'title':'Quantidade Usuários Ind Free', 'color': '#d3d3d3', 'icon_card': <UserOutlined />,'suffix': true},
//     'plans_fam_amt': { 'title': 'Quantidade Usuários Fam', 'color': '#662d91', 'icon_card': <TeamOutlined />,'suffix': true},
//     'plans_fam_amt_paid': {'title':'Quantidade Usuários Fam Pag','color': '#3f8600', 'icon_card': <TeamOutlined />,'suffix': true},
//     'plans_fam_amt_free': {'title':'Quantidade Usuários Fam Free','color': '#d3d3d3', 'icon_card': <TeamOutlined />,'suffix': true}
// }
// const COLORS = ['#662d91','#3f8600','#d3d3d3']


//<Col span={(props.plan === 'plans_amt' || props.plan === 'plans_paid_amt' || props.plan === 'plans_free_amt') ? 4 : 4}>            
const AmountCards = (props) =>{
    return (
        <Card headStyle={{fontSize:'0.8rem'}} bodyStyle={{fontSize:'0.7rem'}} title={(<Tooltip title={props.title}>{props.title}</Tooltip>)} style={{minHeight:250, width:"100%", height:"100%"
       }}>
             {Object.keys(props.plan).map((itemKey,i)=>{
                return <div  key={i}>
                    {Array.isArray(props.plan[itemKey])
                    ?
                    <>
                        <span key="0" className='textAmmountSub'>{itemKey}: <b>{props.plan[itemKey][0]}</b></span>
                        <Divider style={{margin:"5px"}} type="vertical"></Divider>
                        <span key="1" className='textAmmountSub'>Únicos: <b>{props.plan[itemKey][1]}</b></span>
                    </>
                    :
                    <span className='textAmmount'>{itemKey}: <b>{props.plan[itemKey]}</b></span>}
                    
                    {i <  Object.keys(props.plan).length-1 ? <Divider style={{margin:"5px"}}></Divider> : null}
                
                </div>
            })} 
            
        </Card>
    )
} 
/*
<Statistic
                title={props.plan.name_plan}
                value={props.plan.count_id}
                valueStyle={{ color: COLORS[Math.floor(props.key/3)] }}
                // prefix={PLANS[props.plan].icon_card}
                //suffix={PLANS[props.plan].suffix ? ' ~' + ((props.plans_amt[props.plan]/props.plans_amt['plans_amt']) * 100).toFixed(2) + '%' : ''}
            />
<Statistic
            title={props.plan.name_plan}
            value={props.plan.count_id}
            valueStyle={{ color: COLORS[Math.floor(props.key/3)] }}
            // prefix={PLANS[props.plan].icon_card}
            //suffix={PLANS[props.plan].suffix ? ' ~' + ((props.plans_amt[props.plan]/props.plans_amt['plans_amt']) * 100).toFixed(2) + '%' : ''}
            /> */
export default AmountCards;
