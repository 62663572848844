import React from 'react';
import Spinner from './Spinner';

const LoginForm = (props) => {
    return (
        <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-12">
            <div className="card card-signin my-5">
                <div className="card-body">
                <h5 className="card-title text-center">Login</h5>
                <form className="form-signin" onSubmit={props.handleLogin} >
                    <div className="form-label-group">
                        <input type="email" name="email" className="form-control" placeholder="Email" value={props.email} onChange={props.changeField}  required />
                    </div>

                    <div className="form-label-group">
                        <input type="password" name="password" id="inputPassword" className="form-control" placeholder="Password" value={props.password} onChange={props.changeField} required />
                    </div>
                    
                    <br/>
                    {
                        props.setNewPwd ? 
                            <div className="form-label-group">
                                <div className="alert alert-danger" role="alert">
                                    Identificamos que esse é o seu primeiro acesso, coloque sua nova senha
                                </div>

                                <input type="password" name="newPassword" id="inputPassword" className="form-control" placeholder="Novo password" value={props.newPassword} onChange={props.changeField} required />
                            </div> : <div />
                    }

                    {(!props.loading)?<button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Entrar</button>:<Spinner></Spinner>}
                </form>
                </div>
            </div>
            </div>
        </div>
    )
}



export default LoginForm;
