import React, { Component } from 'react'
import { Layout } from 'antd'
import moment from 'moment'
const { Footer } = Layout;

class Footers extends Component {
    render() {
      return <Footer style={{ textAlign: 'center' }}>ProBrain ©{moment().format('YYYY')}</Footer>
    }
}

export default Footers;
