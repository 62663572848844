import React, { useState } from "react";
import classes from "./DeleteUser.module.scss";
import axios from "axios";
import H1 from "../../Components/Titles/H1";
import { Input, Alert } from "antd";
import { ADMIN_SEARCH_BY_ANY } from "../../constants/api";
import Spinner from "../../Components/Spinner";
import ModalDeleteUser from "../../Components/Modais/ModalDeleteUser/ModalDeleteUser";
import ModalUserDeleted from "../../Components/Modais/ModalUserDeleted/ModalUserDeleted";
import ModalUserPaid from "../../Components/Modais/ModalUserPaid/ModalUserPaid";
import ButtonFunction from "../../Components/Buttons/ButtonFunction/ButtonFunction";
import { Auth } from "aws-amplify";

export default function DeleteUser() {
  const [showModal, setShowModal] = useState(false);
  const [showModalDeleted, setShowModalDeleted] = useState(false);
  const [showModalIsPaid, setShowModalIsPaid] = useState(false);
  const [userPosition, setUserPosition] = useState("");
  // const [IsPaid, setIsPaid] = useState();
  const [index, setIndex] = useState(-1);

  const { Search } = Input;

  const [state, setState] = useState({
    error: "",
    progress: 0,
    users: [],
    all_users: [],
    user_email: "",
    user_sub: "",
    product_name: "",
    error: "",
    modal_visible: false,
    modal_visible_free: false,
    loading: false,
    mensagem: 0,
    message_no_user: false,
    change_plan: false,
  });

  const user_types_map = {
    individual_free: "Usuário Gratuito",
    parent_free: "Usuário Gratuito (Pai) - Com Usuários Vinculados",
    patient_paid: "Usuário Pago - Vinculado a Outro Usuário",
    individual_paid: "Usuário Pago",
    limbo: "Email não Verificado",
    patient_free: "Usuário Gratuito - Vinculado a Outro Usuário",
    parent_paid: "Usuário Pago (Pai) - Com Usuários Vinculados",
    individual_trial: "Usuário no Trial",
    grand_paid:
      "Usuário Pago - Vinculado a Outro Usuário e Com Usuários Vinculados",
    grand_free:
      "Usuário Gratuito - Vinculado a Outro Usuário e Com Usuários Vinculados",
  };

  const user_free_map = {
    individual_free: "Gratuita",
    parent_free: "Gratuita",
    patient_free: "Gratuita",
    grand_free: "Gratuita",
    individual_trial: "Gratuita",
    limbo: "Gratuita",
  };

  const searchUserByEmail = (search) => {
    setState({ loading: true, error: "" });
    Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_SEARCH_BY_ANY(search), {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((users) => {
          if (Object.keys(users.data).length > 0) {
            setState({
              ...state,
              users: users.data,
              error: "",
              loading: false,
              message_no_user: false,
            });
          } else {
            setState({ error: "Não foi possivel encontrar esse email" });
          }
        })
        .catch((e) => {
          setState({ error: "Não foi possivel encontrar esse email" });
        });
    });
  };
  const getIndex = (element) => state.users.indexOf(element);

  const handleClick = (element) => {
    const currentIndex = getIndex(element);
    setIndex(currentIndex);
  };

  const renderUser = (user, k) => {
    let IsPaid = user_free_map[user.type_user] ? false : true;

    return (
      <tr className={classes.DataTable} key={k}>
        <th scope="row">{k}</th>
        <td>{user.sub}</td>
        <td>{user.email}</td>
        <td>{user_free_map[user.type_user] ? "Gratuita" : "Pago"}</td>
        <td>
          {user_types_map[user.type_user]
            ? user_types_map[user.type_user]
            : null}
        </td>
        <td>
          <ButtonFunction
            text="Excluir Usuário"
            key={k}
            element={k}
            handleClick={handleClick}
            onClick={() => {
              setUserPosition(k);
              IsPaid
                ? setShowModalIsPaid(!showModalIsPaid)
                : setShowModal(!showModal);
            }}
          />
        </td>
      </tr>
    );
  };

  const renderUsersTable = (users) => {
    return (
      <div>
        <table className="table">
          <thead>
            <tr className={classes.DataTable}>
              <th scope="col">#</th>
              <th scope="col">Sub</th>
              <th scope="col">Email</th>
              <th scope="col">Tipo de Conta</th>
              <th scope="col">Situação da Conta</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(users).length > 0 &&
              users.map((plan, k = +1, all) => renderUser(plan, k, all.length))}
          </tbody>
        </table>
        <hr />
      </div>
    );
  };

  return (
    <div>
      {showModal && (
        <ModalDeleteUser
          users={state.users}
          setShowModal={setShowModal}
          setShowModalDeleted={setShowModalDeleted}
          userPosition={userPosition}
          // setForceUpdate={setForceUpdate}
          // forceUpdate={forceUpdate}
        />
      )}
      {showModalDeleted && (
        <ModalUserDeleted
          users={state.users}
          setShowModalDeleted={setShowModalDeleted}
          // setForceUpdate={setForceUpdate}
          // forceUpdate={forceUpdate}
        />
      )}
      {showModalIsPaid && (
        <ModalUserPaid
          users={state.users}
          setShowModalIsPaid={setShowModalIsPaid}
          // setForceUpdate={setForceUpdate}
          // forceUpdate={forceUpdate}
        />
      )}
      <H1>BUSCAR USUÁRIO A SER EXCLUÍDO</H1>
      <div className="row">
        <div className="col-sm-3">
          <Search
            placeholder="Email"
            onSearch={(value) => searchUserByEmail(value)}
            size="large"
            style={{ borderColor: "#fff" }}
            loading={state.loading}
          />
          {state.error && <Alert message={state.error} type="warning" />}
        </div>

        <div className="col-sm-12">
          {state.users &&
            Object.keys(state.users).length > 0 &&
            renderUsersTable(state.users)}
          {state.loading && <Spinner />}
        </div>
      </div>
    </div>
  );
}
