// import { faBullseye } from "@fortawesome/fontawesome-free-solid";

let config = {
    xField: 'days',
    yField: ['amt','amt'],
    height:500,
    geometryOptions: [
      {
        geometry: 'column',
        seriesField: 'name',groupField: 'type',
        isGroup: true,isStack: true,
      },
      {
        seriesField: 'name',
        isGroup: false,isStack: false,
        
        geometry: 'line',
        lineStyle: {
          lineWidth: 1,
        },
        point: {
          size: 5,
          shape: 'diamond',
          style: {
            fill: 'white',
            stroke: '#5B8FF9',
            lineWidth: 2,
          },
        },
      }
      
      
    ],
    legend: {
      maxRow: 5
    },
     
  };

  export default config