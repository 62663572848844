import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom'

import classes from "./RegisteredCompanies.module.scss";
import H1 from "../../Components/Titles/H1";
import axios from "axios";
import { Input, Alert } from "antd";
import {
  ADMIN_GET_COMPANIES_SEARCH,
  ADMIN_GET_COMPANIES,
} from "../../constants/api";
import ModalSuperAdmin from "../../Components/Modais/ModalSuperAdmin/ModalSuperAdmin";
import ModalAddJourneys from "../../Components/Modais/ModalAddJourneys/ModalAddJourneys";
import Spinner from "../../Components/Spinner";
import ModalAddCompany from "../../Components/Modais/ModalAddCompany/ModalAddCompany";
import { Auth } from "aws-amplify";
import ButtonFunction from "../../Components/Buttons/ButtonFunction/ButtonFunction";
import moment from "moment";
import ModalCompanyRegistered from "../../Components/Modais/ModalCompanyRegistered/ModalCompanyRegistered";

const { Search } = Input;

const RegisteredCompanies = () => {
  const [state, setState] = useState({
    error: "",
    progress: 0,
    users: [],
    all_users: [],
    user_email: "",
    user_sub: "",
    product_name: "",
    modal_visible: false,
    modal_visible_free: false,
    loading: false,
    mensagem: 0,
    message_no_user: false,
    change_plan: false,
  });
  const [showModalNewCompany, setShowModalNewCompany] = useState();
  const [showModalAdmin, setShowModalAdmin] = useState(false);
  const [showModalAddJourney, setShowModalAddJourney] = useState(false);
  const [hasSuccessInUpdate, setHasSuccessInUpdate] = useState(false);
  const [getCompany, setGetCompany] = useState();
  const [adminPosition, setAdminPosition] = useState();
  const [companyUid, setCompanyUid] = useState("");
  const [planPermission, setPlanPermission] = useState("");
  const [showModalCompanyAdded, setShowModalCompanyAdded] = useState(false);
  const [updateCompany, setUpdateCompany] = useState(false);

  useEffect(() => {
    allCompanies();
  }, [updateCompany, hasSuccessInUpdate]);

  const searchUserByEmailAndCompany = (search) => {
    setState({ loading: true, error: "" });
    Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_GET_COMPANIES_SEARCH(search), {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((users) => {
          setGetCompany(users.data);
          if (Object.keys(users.data).length > 0) {
            setState({
              ...state,
              users: users.data,
              error: "",
              loading: false,
              message_no_user: false,
            });
          } else {
            setState({ error: "Não foi possivel encontrar esse email" });
          }
        })
        .catch((e) => {
          setState({ error: "Não foi possivel encontrar esse email" });
        });
    });
  };

  const allCompanies = () => {
    Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_GET_COMPANIES, {
          headers: {
            Authorization: Auth.user.signInUserSession?.idToken.jwtToken,
          },
        })
        .then((resp) => {
          setGetCompany(resp.data);
        })
        .catch((e) => {});
    });
  };

  const renderUser = (company, k) => {
    let CompanyActive = company?.active === 1 ? "Sim" : "Não";
    return (
      <tr className={classes.DataTable} key={k}>
        <th scope="row">{k}</th>
        <td>{company?.company_uid}</td>
        <td>{company?.company_name}</td>
        <td>{company?.plan_name}</td>
        <td>
          {" "}
          <ButtonFunction
            onClick={() => {
              setShowModalAdmin(true);
              setAdminPosition(k);
            }}
            text="ver"
          />{" "}
        </td>
        <td>{company?.limit_users}</td>
        <td>{company?.limit_users_smart}</td>
        <td>{CompanyActive}</td>
        <td>{moment(company?.date_created).format("DD/MM/YYYY")}</td>
        <td>
          {" "}
          <ButtonFunction
            onClick={() => {
              setShowModalAddJourney(true);
              setAdminPosition(k);
              setCompanyUid(company?.company_uid)
              setPlanPermission(company?.plan_permission)
            }}
            text="ver"
          />{" "}
        </td>
      </tr>
    );
  };

  const renderUsersTable = (company) => {
    return (
      <div>
        <table className="table">
          <thead>
            <tr className={classes.DataTable}>
              <th scope="col">#</th>
              <th scope="col">Código Empresa</th>
              <th scope="col">Nome Empresa</th>
              <th scope="col">Tipo de Plano</th>
              <th scope="col">Super Admins</th>
              <th scope="col">N° Usuários Go</th>
              <th scope="col">N° Usuários Smart</th>
              <th scope="col">Empresa Ativa</th>
              <th scope="col">Data de Criação</th>
              <th scope="col">Adicionar Triagens</th>
            </tr>
          </thead>
          <tbody>
            {company &&
              company
                .sort((a, b) => b.id - a.id)
                .map((plan, k = +1, all) => renderUser(plan, k, all.length))}
          </tbody>
        </table>
        <hr />
      </div>
    );
  };

  return (
    <>
      <div>
        <H1>EMPRESAS CADASTRADAS</H1>
        {showModalNewCompany && (
          <ModalAddCompany
            setShowModalNewCompany={setShowModalNewCompany}
            setShowModalCompanyAdded={setShowModalCompanyAdded}
          />
        )}
        {showModalAdmin && (
          <ModalSuperAdmin
            setShowModalAdmin={setShowModalAdmin}
            getCompany={getCompany}
            adminPosition={adminPosition}
          />
        )}
        {showModalAddJourney && (
          <ModalAddJourneys
            setShowModalAddJourney={setShowModalAddJourney}
            setHasSuccessInUpdate={setHasSuccessInUpdate}
            hasSuccessInUpdate={hasSuccessInUpdate}
            companyUid={companyUid}
            planPermission={planPermission}
          />
        )}
        {showModalCompanyAdded && (
          <ModalCompanyRegistered
            setShowModalCompanyAdded={setShowModalCompanyAdded}
            setUpdateCompany={setUpdateCompany}
          />
        )}
        <div className="row">
          <div className="col-sm-3">
            <Search
              id={"search"}
              placeholder="Email"
              onSearch={(value) => searchUserByEmailAndCompany(value)}
              size="large"
              style={{ borderColor: "#fff" }}
              loading={state.loading}
            />
            {state.error && <Alert message={state.error} type="warning" />}
          </div>
          <button
            className={classes.BtnAddPlan}
            onClick={() => setShowModalNewCompany(true)}
          >
            adicionar empresa
          </button>
          <div className={classes.DataTable}>
            {getCompany &&
              Object.keys(getCompany).length > 0 &&
              renderUsersTable(getCompany)}
            {state.loading && <Spinner />}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisteredCompanies;
