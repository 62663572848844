import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { ADMIN_GET_EMAILS } from "../../constants/api";
import classes from "./UserDataLinked.module.scss";
import H1 from "../../Components/Titles/H1";
import ButtonBack from "../../Components/Buttons/ButtonBack/ButtonBack";
import { Divider, Button } from "antd";
import * as routes from "./../../constants/routes";
import { Auth } from "aws-amplify";

// const NEXT_URL = 'https://mcqj5lejdg.execute-api.us-east-1.amazonaws.com/v1'
//const NEXT_URL = 'https://k9357p16ih.execute-api.us-east-1.amazonaws.com/next-dev-2/'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailParent: props.location?.state?.emailParent,
      users: [],
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_GET_EMAILS(this.props.match.params.parent_sub), {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((users) => {
          this.setState({ users: users.data });
        })
        .catch((e) => {});
    });
  }

  renderUser(user, k) {
    return (
      <tr key={k} className={classes.DataTable}>
        <th scope="row">{k}</th>
        <td>{user.sub}</td>
        <td>
          <Link
            to={{
              pathname: routes.R_GET_USER_DETAILS(user.email),
              state: { user: user, parentSub: user.parent_sub },
            }}
          >
            {user.email}
          </Link>
        </td>
        <td>
          {user.parent_activity_status == "true"
            ? "Ativo"
            : user.parent_activity_status == "false"
            ? "Congelado"
            : "Legado"}
        </td>
        <td>{user.parent_sub}</td>
        <td>{user.tasks_amt}</td>
      </tr>
    );
  }

  renderTableUsers(users) {
    return (
      <table className="table">
        <thead>
          <tr className={classes.DataTable}>
            <th scope="col">#</th>
            <th scope="col">Sub</th>
            <th scope="col">Email</th>
            <th scope="col">Situação</th>
            <th scope="col">Sub do Usuário Pai</th>
            <th scope="col">N° de Atividades Vinculadas</th>
          </tr>
        </thead>
        <tbody>{users.map((user, k = +1) => this.renderUser(user, k))}</tbody>
      </table>
    );
  }

  renderButtonUsers(user) {
    return (
      <div>
        <ButtonBack
          text="Voltar"
          to={
            this.state.emailParent
              ? routes.R_GET_USER_DETAILS(this.state.emailParent)
              : routes.R_PLANS_USERS
          }
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <H1 className={classes.TitleAdjusted}>
          USUÁRIOS VINCULADOS
          {this.state.emailParent && (
            <>
              <Divider type="vertical" />
              <b>{this.state.emailParent}</b>
            </>
          )}
        </H1>

        {this.renderButtonUsers(this.state.users)}
        <div className="row">
          <div className="col-sm-12">
            {this.state.users.length > 0 ? (
              this.renderTableUsers(this.state.users)
            ) : (
              <div>Esse usuário não possui vinculos com outros usuários.</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
