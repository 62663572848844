import React, { useState, useEffect, useRef } from "react";
import classes from "./Permission.module.scss";
import * as helpers from "../../helpers/utilityHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import {
  Input,
  Button,
  Modal,
  Col,
  Row,
  Tag,Descriptions,
  Select,
  List, Card, Space, Divider,
} from "antd";
import { CloseOutlined, EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import PermissionModel from "./Model/PermissionModel.js";

const Permission = ({
  options,
  active: initialActive,
  dark,
  huge,
  rewards = [],
  className = "",
  description = "",
  label = "",
  onChange = () => { },
  value = {}
}) => {
  const [permissionsKey, setPermissionsKey] = useState(null)
  const [modalEditIndex, setModalEditIndex] = useState(-1)
  const [openModal, setOpenModal] = useState(false);
  const [tmpRole, setTmpRole] = useState(null)
  const permissionModalRef = useRef();


  useEffect(() => {
    if (initialActive) {
      setPermissionsKey(initialActive);
    }
  }, [value]);

  // const [content, setContent] = useState(null);
  // const [contentState, setContentState] = useState(1);
  const reward_map_name = (rewards.length > 0) ? rewards.reduce((a, r) => {
    a[r.slug] = r.id
    return a
  }, {}) : "int"
  const optionsPermissions = {
    "user_property_validation": {
      "name": "Regras dos Usuários",
      "placeholderField": "Selecione a propriedade do Usuário",
      "placeholderOperator": "Selecione a operação que será comparada",
      "placeholderInput": "Selecione o valor da comparação",
      "options": {
        "have": "have",
        "not_have": "not_have",
        "not_equal": "input",
        "equal": "input",
        "in": "list",
        "not_in": "list",
        // "greater_than": "input",
        // "less_than": "input",
        // "greater_than_or_equal": "input",
        // "less_than_or_equal": "input"
      },
      "fields": {
        "id": "int",
        "sub": "str",
        "parent_sub": "str",
        "parent_activity_status": "str",
        "email": "str",
        "phone": "str",
        "profile": "str",
        "email_verified": "int",
        "phone_verified": "int",
        "used_code": "str",
        "fiscal_document": "str",
        "full_name": "str",
        "given_name": "str",
        "gender": "str",
        "profession": "str",
        "education": "str",
        "address_street": "str",
        "address_number": "str",
        "address_complement": "str",
        "address_neighborhood": "str",
        "address_zipcode": "str",
        "address_city": "str",
        "address_state": "str",
        "address_country": "str",
        "avatar": "str",
        "signup_platform": "str",
        "date_created": "date",
        "date_updated": "date"
      }
    },
    "plans_property_validation": {
      "name": "Regras dos Planos",
      "placeholderField": "Selecione a propriedade do Plano",
      "placeholderOperator": "Selecione a operação que será comparada",
      "placeholderInput": "Selecione o valor da comparação",
      "options": {
        "have": "have",
        "not_have": "not_have",
        "not_equal": "input",
        "equal": "input",
        "in": "list",
        "not_in": "list",
        // "greater_than": "input",
        // "less_than": "input",
        // "greater_than_or_equal": "input",
        // "less_than_or_equal": "input"
      },
      "fields": {
        "id": "int",
        "sub": "str",
        "application": "str",
        "application_name": "str",
        "product_id": "int",
        "card_id": "int",
        "order_id": "int",
        "cupom_id": "int",
        "cancellation_id": "int",
        "recurrence": "str",
        "name": "str",
        "type": "str",
        "periodicity": "str",
        "language": "str",
        "price": "float",
        "currency": "str",
        "status": "str",
        "description": "str",
        "url": "str",
        "image_url": "str",
        "permission": "str",
        "observations": "str",
        "payment_method": "str",
        "is_legacy": "str",
        "first_charge": "date",
        "last_charge": "date",
        "next_charge": "date",
        "date_created": "date",
        "date_updated": "date"
      }
    },
    "reward_repeat_pattern_check": {
      "name": "Padrões de Repetição",
      "placeholderField": "Selecione a propriedade de repetição",
      "placeholderInput": "Selecione o valor",
      "fields": {
        "repeat_pattern": ["day", "week", "month", "year"],
        "max_repeats": "int",
        "reward_id": (rewards.length > 0) ? rewards.map(r => { return { "value": r.id, "label": r.slug } }) : "int",
        "operator": ["<", "<=", ">", ">=", "==", "!=)"]
      }
    },
    "range_periods": {
      "name": "Período Permitido",
      "placeholderField": "Selecione a propriedade de repetição",
      "placeholderInput": "Insira o valor",
      "fields": {
        "periods": [{
          "init": "str",
          "end": "str",
          "pattern": "str"
        }]
      }
    },
  }

  useEffect(() => {
    if (value) {
      let hasInfo = true
      const newTmpRole = Object.keys(value).reduce((fRole, role) => {
        // console.log("Role", value[role])
        fRole[role] = value[role].map((item) => {
          hasInfo = hasInfo && item.hasOwnProperty("_info")
          if (!item.hasOwnProperty("_info"))
            item["_info"] = generateInfo(item)

          return item
        })
        return fRole
      }, {})
      if (!hasInfo)
        whenChange(newTmpRole)
    }

  }, [value])

  const removePermission = (opt, i) => {
    //console.log('addPermission', opt)
    const newValue = value[opt]
    newValue.splice(i, 1)
    //console.log('newValue', newValue)
    const currentValue = { ...value, [opt]: newValue }
    //console.log('currentValue', currentValue)
    // setContentState(contentState + 1)
    whenChange(currentValue)
  }
  const mapInfo = {
    "array": (list,prefixDefault = "",isArray=false) => {
      return list.reduce((accString, val) => {
        const typeValue = Array.isArray(val) ? 'array' : typeof val
        let fValue = val
        if (mapInfo.hasOwnProperty(typeValue)) fValue =  mapInfo[typeValue](val,undefined,true) 
        if(Array.isArray(fValue))
        accString.push(...fValue)
        else
        accString.push(fValue)
        return accString
      }, [])
    },
    "object": (_obj,prefixDefault = "",isArray=false) => {
      return Object.keys(_obj).reduce((acc, key) => {
        const typeValue = Array.isArray(_obj[key]) ? 'array' : typeof _obj[key]
        let fValue = _obj[key]
        if (mapInfo.hasOwnProperty(typeValue)) fValue = mapInfo[typeValue](_obj[key])
        acc.push(<Descriptions.Item span={0} label={key} >  {fValue}</Descriptions.Item> )
        return acc
      }, [])
    },
    "string": (_str, prefixDefault = " igual ",isArray=false) => {
      const stringMap = {
        "have": " existe ",
        "not_have": " não existe "
      }
      const label = stringMap.hasOwnProperty(_str) ? stringMap[_str] : prefixDefault 
      const value = stringMap.hasOwnProperty(_str) ? null : "" + _str + ""
      return <Descriptions.Item span={0} label={(!isArray)?label:undefined}>{value}</Descriptions.Item>
    },
    "number": (_str, prefixDefault = " igual a ",isArray=false) => {
      const stringMap = {
        "have": " existe ",
        "not_have": " não existe "
      }
      const label = stringMap.hasOwnProperty(_str) ? stringMap[_str] : prefixDefault 
      const value = stringMap.hasOwnProperty(_str) ? null : "" + _str + ""

      return <Descriptions.Item span={0} label={(!isArray)?label:undefined}>{value}</Descriptions.Item>
    }
  }

  const generateInfo = (objRole) => {
    return Object.keys(objRole).reduce((finalArray, key) => {

      const typeValue = Array.isArray(objRole[key]) ? 'array' : typeof objRole[key]
      let finalString = key

      let valueInfo = objRole[key]
      if (mapInfo.hasOwnProperty(typeValue)) {
        valueInfo = mapInfo[typeValue](valueInfo)
      }
      const spanItem = (Array.isArray(valueInfo)?3:0)
      const suffix = (Array.isArray(valueInfo)?" entre:":"")
      
      finalArray.push( <Descriptions layout="horizontal" ><Descriptions.Item span={spanItem}>{finalString+suffix}</Descriptions.Item>{valueInfo}</Descriptions>)
      return finalArray

    }, [])
  }

/*   const mapInfo = {
    "array": (list) => {
      return list.reduce((accString, val) => {
        const typeValue = Array.isArray(val) ? 'array' : typeof val
        let fValue = val
        if (mapInfo.hasOwnProperty(typeValue)) fValue = "\n[" + mapInfo[typeValue](val) + "\n]"
        accString = accString + fValue
        return accString
      }, " in (") + " )"
    },
    "object": (_obj) => {
      return Object.keys(_obj).reduce((acc, key) => {
        const typeValue = Array.isArray(_obj[key]) ? 'array' : typeof _obj[key]
        let fValue = _obj[key]
        if (mapInfo.hasOwnProperty(typeValue)) fValue = mapInfo[typeValue](_obj[key])
        acc = acc + "\n" + key + " " + fValue + " "
        return acc
      }, "")
    },
    "string": (_str, prefixDefault = " igual ") => {
      const stringMap = {
        "have": " existe ",
        "not_have": " não existe "
      }
      return (stringMap.hasOwnProperty(_str) ? stringMap[_str] : prefixDefault + " (" + _str + ")")
    },
    "number": (_str, prefixDefault = " igual a ") => {
      const stringMap = {
        "have": " existe ",
        "not_have": " não existe "
      }
      return (stringMap.hasOwnProperty(_str) ? stringMap[_str] : prefixDefault + " (" + _str + ")")
    }
  }

  const generateInfo = (objRole) => {
    return Object.keys(objRole).reduce((finalArray, key) => {

      const typeValue = Array.isArray(objRole[key]) ? 'array' : typeof objRole[key]
      let finalString = key

      let valueInfo = objRole[key]
      if (mapInfo.hasOwnProperty(typeValue)) {
        valueInfo = mapInfo[typeValue](valueInfo)
      }

      finalString = finalString + " " + valueInfo
      finalArray.push(finalString)
      return finalArray

    }, [])
  } */

  const whenChange = (currentValue) => {

    onChange(currentValue)
  }

  const addPermission = (opt, new_obj_value) => {
    //console.log('addPermission', opt)
    //console.log('value', value)
    // const lenOpt = (value[opt] ? value[opt].length : 0) + 1
    let newValue = []
    let currentValue = value
    if (value[opt] !== undefined) {
      newValue = value[opt]
    }

    new_obj_value["_info"] = generateInfo(new_obj_value)

    newValue.push(new_obj_value)
    //console.log('newValue', newValue)

    currentValue[opt] = newValue;
    //console.log('currentValue', currentValue)
    // setContentState(contentState + 1)
    whenChange(currentValue)
    setOpenModal(false)
  }

  const editPermission = (opt, new_obj_value, i) => {
    //console.log('editPermission', opt)
    //console.log('value', value)
    // const lenOpt = (value[opt] ? value[opt].length : 0) + 1
    let newValue = []
    let currentValue = value
    if (value[opt] !== undefined) {
      newValue = value[opt]
    }

    new_obj_value["_info"] = generateInfo(new_obj_value)

    newValue.splice(i, 1, new_obj_value)

    //console.log('newValue', newValue)

    currentValue[opt] = newValue;
    //console.log('currentValue', currentValue)
    // setContentState(contentState + 1)
    whenChange(currentValue)
    setOpenModal(false)
    setTmpRole({});
    setModalEditIndex(-1);
    setPermissionsKey("");
    if (permissionModalRef && permissionModalRef.hasOwnProperty("stateModalEdit"))
      permissionModalRef.stateModalEdit("")
  }

  const updateModalContent = (keyPermission, item = { "": "" }, index = -1) => {
    if (!openModal) {
      //console.log('keyPermission', keyPermission)
      //console.log('item', item)
      const itemModel = JSON.parse(JSON.stringify(item))
      delete itemModel["_info"]
      setModalEditIndex(index)
      setPermissionsKey(keyPermission)
      setTmpRole(itemModel)
      setOpenModal(true)
    }
  }





  return (<>
    <h5>{label}</h5>
    <p>{description}</p>
    <Row gutter={4} justify="end">
      {Object.keys(optionsPermissions).map((opt, i, all) => <Col key={"c" + i} span={all.length / 12}><Button type="link" onClick={(e) => { updateModalContent(opt) }}>{"+ " + optionsPermissions[opt].name}</Button></Col>)}
    </Row>
    <Row gutter={1} className={classes.RowPermissions}>
      {Object.keys(optionsPermissions).map((opt, i, all) => {
        return <Col span="6" key={"col" + i}>
          <List
            header={<b>{optionsPermissions[opt].name}</b>}>
            <Space size={0} direction="vertical" style={{ width: "100%" }} split={<Divider orientation="left" type="horizontal" >E (&&, AND)</Divider>}>
              {value && value[opt] && value[opt].map((item, i) => {
                return (
                  <Row direction="horizontal">
                    <Col span="3">
                      
                      <Space size={0} direction="vertical" split={<Divider style={{ margin: "6px 0" }} orientation="left" type="horizontal" ></Divider>}>
                        {(i + 1) + "º): "}
                        <EditOutlined onClick={(e) => { updateModalContent(opt, item, i) }} color="red" key="edit" />
                        <CloseOutlined onClick={(e) => { removePermission(opt, i) }} color="red" key="ellipsis" />
                      </Space>
                    </Col>
                    <Col span="21">
                      {/* <Card bordered={false}> */}
                      <Space size={0} direction="vertical" split={<Divider style={{ margin: "5px 0" }} orientation="left" type="horizontal" ></Divider>}>
                        {/* {item['_info']?.map((info, i) =>
                          <span>
                            {info.split('\n').map(
                              (i, _i, all) =>
                                <p>
                                  {i}
                                </p>
                            )}
                          </span>
                        )} */}
                        {item['_info']?.map((info, i) =>info)}
                      </Space>
                      {/* </Card> */}
                    </Col>
                  </Row>)
              })}
            </Space>
          </List>
        </Col>
      })}
      <Modal
        open={openModal}
        title={openModal && optionsPermissions[permissionsKey].name}
        width={1000}
        onCancel={() => {
          permissionModalRef.current.setStateModalEdit("")
          setModalEditIndex(-1);
          setTmpRole({});
          setPermissionsKey("");
          setOpenModal(false);
        }}
        onOk={() => {
          (modalEditIndex === -1) ? addPermission(permissionsKey, tmpRole) : editPermission(permissionsKey, tmpRole, modalEditIndex)

        }}
        value={tmpRole}
        maskStyle={{}}
      >
        {
          optionsPermissions[permissionsKey] && openModal && (
            <PermissionModel
              ref={permissionModalRef}
              modalEditIndex={modalEditIndex}
              optionsPermissions={optionsPermissions}
              permissionsKey={permissionsKey}
              openModal={openModal}
              setOpenModal={setOpenModal}
              tmpRole={tmpRole}
              setTmpRole={setTmpRole} />
          )
        }
      </Modal>
    </Row>
  </>
  );
};

export default Permission;
