import React from "react";
import classes from "../ButtonBack/ButtonBack.module.scss";
import { Link } from "react-router-dom";

const ButtonBack = ({ size, rounded, to, className = "", text, children }) => {
  // Check the button size

  return (
    <Link to={to ? to : "/"} className={classes.ButtonBacks}>
      <span>{children ? children : text ? text : null}</span>
    </Link>
  );
};

export default ButtonBack;
