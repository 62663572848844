import React from "react";
import classes from "../ButtonLink/ButtonLink.module.scss";
import { Link } from "react-router-dom";

const ButtonBack = ({ size, rounded, to, className = classes.ButtonBacks,text, children }) => {

  // Check the button size

  return (
    <Link to={to ? to : "/"} className={className}>
      <span>{children ? children : text ? text : null}</span>
    </Link>
  );
};

export default ButtonBack;
