import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import LoginForm from './Components/LoginForm'

import Amplify, { Auth } from 'aws-amplify'
import AWS_EXPORTS_PROD from './aws-exports-prod'

Amplify.configure(AWS_EXPORTS_PROD)

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      newPassword: '',
      code: '',
      setNewPwd: false,
      user: [],
      error: '',
      redirect: false,
      loading: false,
      changePwd: false,
    };
  }

  componentDidMount() {
      Auth.currentAuthenticatedUser().then(user => {
        this.setState({redirect: true})
      });
  }

  changeField(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  async handleLoginAction(e) {
    e.preventDefault()
    let data = {
      username: this.state.email,
      password: this.state.password    
    }
    this.setState({loading: true})

    Auth.signIn(data).then(res => {
      if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setState({setNewPwd: true, user: res,loading: false})
      } else {
        this.setState({user_data: JSON.stringify(res.attributes, undefined, 2), error: '', redirect: true})
      }
    }).catch(err => {
      this.setState({error: err.message,loading: false})
    })
   }

   async handleLoginByNewPwdAction(e) { 
    e.preventDefault()
    let data = {
      username: this.state.email, 
      name: 'ProBrain DEV Admin',
      password: this.state.newPassword    
    }
    
    Auth.completeNewPassword(this.state.user, this.state.newPassword).then(cnp => {
        Auth.signIn(data).then(user => {
          this.setState({user_data: JSON.stringify(user.attributes, undefined, 2), error: '', redirect: true})
        }).catch(err => console.log(err));
    }).catch(err => console.log(err));
   }
   
   renderErrorMessage(error) {
       return (
            <div className="alert alert-danger" role="alert">{error}</div>
       )
   }

  render () {
    if (this.state.redirect) {
        return <Redirect to='/admin/planos-do-usuario' />
    }

    return (
        <div className="container">
            <LoginForm loading={this.state.loading} handleLogin={(e) => this.state.setNewPwd ? this.handleLoginByNewPwdAction(e) : this.handleLoginAction(e)} changeField={(e) => this.changeField(e)} 
            email={this.state.email} password={this.state.password} newPassword={this.state.newPassword} setNewPwd={this.state.setNewPwd}  />

            { this.state.error.length > 0 ? this.renderErrorMessage(this.state.error) : <div />}
        </div>
    )
  }
}

export default Login;
