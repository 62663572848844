import React, { useRef, useEffect, useState } from "react";
// import ReactDOMServer from "react-dom/server";
import classes from "./ModalDeleteUser.module.scss";
import { Auth } from "aws-amplify";
import axios from "axios";
import { ADMIN_REMOVE_USER } from "../../../constants/api";
import Spinner from "../../Spinner";

const ModalDeleteUser = ({
  users,
  setShowModal,
  setShowModalDeleted,
  userPosition,
}) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;

  const [loginInfo, setLoginInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openState, setModal] = useState(classes.WrapModalhide);

  useEffect(() => {
    setModal(classes.WrapModal);
  }, []);

  const addPlan = () => {
    Auth.currentAuthenticatedUser().then((u) => {
      let data = {
        admin_email: loginInfo,
        user_sub: users[userPosition].sub,
      };

      axios
        .post(ADMIN_REMOVE_USER, data, {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((resp) => {
          setShowModal(false);
          setShowModalDeleted(true);
        })
        .catch((e) => {
          setLoading(false);
          setError("Falha ao excluir usuário");
        });
    });
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setLoginInfo(user.attributes.email);
      })
      .catch((e) => {
      });
  }, []);

  //Content
  content_modal = (
    <div className={openState} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setModal(classes.WrapModalhide);
                setShowModal(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>
              TEM CERTEZA QUE DESEJA EXCLUIR O USUÁRIO E TODAS AS SUAS
              INFORMAÇÕES SALVAS EM NOSSO SISTEMA?
            </div>

            <div>
              <div className={classes.ModalLabel}>
                <div orientation="center" className={classes.ModalUserEmail}>
                  {users[userPosition].email}
                </div>
              </div>
            </div>
            <div className={classes.OptionsYesOrNo}>
              <button
                tabIndex="0"
                onClick={() => {
                  addPlan();
                  setLoading(true);
                }}
                className={classes.CloseButtonText}
              >
                SIM
              </button>
              <button
                tabIndex="0"
                onClick={() => {
                  setModal(classes.WrapModalhide);
                  setShowModal(false);
                }}
                className={classes.CloseButtonText}
              >
                NÃO
              </button>
            </div>
            {error !== "" && <div className={classes.ErrorMessage}>{error}</div>}

            {loading && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalDeleteUser;
