import React from "react";
import classes from "../ButtonFunctionIncludePlan/ButtonFunctionIncludePlan.module.scss";

const ButtonFunctionIncludePlan = ({
  text,
  onClick = () => {},
  tabindex = "text",
  children,
}) => {
  // Check the button size

  return (
    <button
      tabindex={tabindex}
      className={classes.ButtonFunction}
      onClick={onClick ? onClick : ""}
    >
      <span>{children ? children : text ? text : null}</span>
    </button>
  );
};

export default ButtonFunctionIncludePlan;
