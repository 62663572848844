let config = {
    appendPadding: 10,
    angleField: 'amt',
    colorField: 'profession',
    radius: 0.8,
    label: {
        type: 'outer',
        content: '{name}',
        labelHeight: 10,
      },
    interactions: [
        {
          type: 'element-highlight',
        },
        {
          type: 'custom-association-filter',
        }
      ]
}

export default config