import React, { useState, useEffect, useRef } from "react";
import classes from "../Recompensas.module.scss";
import { ADMIN_REWARDS, AFINANDO_STORE,TAG_MAP_RENDER } from "../../../constants/api";
import { R_REWARDS } from "../../../constants/routes";
import axios from "axios";
import { Auth } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Spinner from "../../../Components/Spinner";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Checkbox,
  Col,
  Row,
  AutoComplete,
  Tag,
} from "antd";
import Permission from "../../../Components/Permission/Permission";

const EditReward = (props) => {
  const history = useHistory();
  // const [inputValues, setInputValues] = useState({});
  const [rewardValues, setRewardValues] = useState({});
  const inputValues = useRef({});
  const [erroAddReward, setErroAddReward] = useState({});
  const [isEditReward, setIsEditReward] = useState(false);
  const [editState, setEditState] = useState("init");
  const [successAddReward, setSuccessAddReward] = useState("");
  const [rewards,setRewards] = useState([])
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={(TAG_MAP_RENDER.hasOwnProperty(value)?TAG_MAP_RENDER[value]:"geekblue")}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const [rewardsElements, setRewardsElements] = useState([
    {
      name: "active",
      typeComponent: "checkbox",
      type: "string",
      required: false,
    },
    {
      name: "auto_claimed",
      typeComponent: "checkbox",
      type: "string",
      required: false,
    },
    {
      name: "reward_name",
      typeComponent: "input",
      type: "string",
      required: true,
    },
    {
      name: "star_price",
      typeComponent: "input",
      type: "number",
      required: true,
    },
    {
      name: "reward_description",
      typeComponent: "textArea",
      type: "string",
      required: true,
    },
    {
      name: "reward_media",
      typeComponent: "input",
      type: "string",
      required: false,
    },
    {
      name: "max_instalments",
      typeComponent: "input",
      type: "number",
      required: false,
    },
    {
      name: "tags",
      typeComponent: "select",
      type: "string",
      options: (tmpValues)=>{
        return tmpValues['all_tags']
      },
      mode: "tags",
      required: false,
    },
    {
      name: "category",
      typeComponent: "autocomplete",
      options: [
        { value: "ToyArts", label: "ToyArts" },
        { value: "ColoringPages", label: "ColoringPages" },
        { value: "AnswerSheets", label: "AnswerSheets" },
        { value: "WallpapersDesktop", label: "WallpapersDesktop" },
        { value: "WallpapersMobile", label: "WallpapersMobile" },
        { value: "GameLevels", label: "GameLevels" },
        { value: "GameCharacters", label: "GameCharacters" },
        { value: "Avatars", label: "Avatars" },
        {
          value: "Accessories (Afinandinho's World)",
          label: "Accessories (Afinandinho's World)",
        },
        { value: "Boosts", label: "Boosts" },
        { value: "LanguagePacks", label: "LanguagePacks" },
        { value: "OnlineClasses", label: "OnlineClasses" },
        { value: "PaperPuppets", label: "PaperPuppets" },
        { value: "MemoryGame", label: "MemoryGame" },
        { value: "SevenErrors", label: "SevenErrors" },
        { value: "TableTopDeck", label: "TableTopDeck" },
        
        { value: "FingerPuppet", label: "FingerPuppet" },
        { value: "DollDressUp", label: "DollDressUp" },
        { value: "AR", label: "AR" },
        { value: "WebComics", label: "WebComics" },
        { value: "Unlockables", label: "Unlockables" },
        { value: "CharacterSkin", label: "CharacterSkin" },
        { value: "ScenarySkin", label: "ScenarySkin" },
        { value: "BonusLevel", label: "BonusLevel" },
        { value: "Uncategorized", label: "Uncategorized" },
      ],
      type: "string",
      required: true,
    },
    // { name: "resource", typeComponent: "input", type: "string", required: true },
    {
      name: "slug",
      typeComponent: "input",
      type: "string",
      required: true,
      disabled: true,
    },
    {
      name: "thumbnail",
      typeComponent: "upload",
      type: "file",
      accept: ".png",
      required: true,
      fileList: (reward) => [
        {
          url: `${AFINANDO_STORE}/${
            reward.slug
          }/thumbnail.png?timeStamp=${new Date().getTime()}`,
        },
      ],
      // fileList: [{ url: `${AFINANDO_STORE}/slug/thumbnail.png` }],
    },
    {
      name: "resource",
      typeComponent: "upload",
      type: "file",
      accept: "*",
      required: false,
      fileList: (reward) => [
        { url: `${reward.resource_url}&timeStamp=${new Date().getTime()}` },
      ],
      // fileList: [{ url: "resource_url" }],
    },
    // {"name": "permission", "typeComponent": "input", "type": "string", required: true},
  ]);

  const spinner = <Spinner />;

  useEffect(() => {
    if(rewards.length===0){
      const _rewards = localStorage.getItem("rewards");
      if (_rewards) {
        setRewards(JSON.parse(_rewards));
      }
    }
    if (editState === "init") {
      if (Object.keys(rewardValues).length > 0) {
        setEditState("started");
      } else {
        putRewards();
      }
    } else if (editState === "started") {
      setContent(
        <Form
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 16 }}
          size="large"
          layout="horizontal"
        >
          {erroAddReward.hasOwnProperty("error") ? (
            <Tag style={{ fontSize: "20px" }} color="error">
              {erroAddReward.error}
            </Tag>
          ) : null}
          {successAddReward ? (
            <Tag style={{ fontSize: "20px" }} color="success">
              {successAddReward}
            </Tag>
          ) : null}

          <Row gutter={4}>
            {rewardsElements.map((reward, index) => (
              <Col key={index} span={8}>
                <Form.Item
                  label={getHeadTableNames(reward.name)}
                  help={
                    erroAddReward.hasOwnProperty(reward.name)
                      ? erroAddReward[reward.name]
                      : null
                  }
                  validateStatus={
                    erroAddReward.hasOwnProperty(reward.name) ? "error" : ""
                  }
                >
                  {getFieldsByRewardType(reward, index)}
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Permission
            onChange={(value) => handleInputChange(value, "permissions")}
            value={rewardValues["permissions"]}
            label="Permissões"
            rewards={rewards}
            description="Regras para listar a recompensa para o usuário."
          ></Permission>
          <Permission
            onChange={(value) => handleInputChange(value, "conditions")}
            value={rewardValues["conditions"]}
            label="Condições"
            rewards={rewards}
            description="Regras para o usuário resgatar a recompensa."
          ></Permission>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <div className={classes.addRewardArea}>
              <Button
                type="primary"
                onClick={() => putRewards(inputValues.current)}
                disabled={isEditReward}
              >
                {!isEditReward ? "Editar" : "Carregando..."}
              </Button>
              <Button type="secondary" onClick={() => history.push(R_REWARDS)}>
                Voltar
              </Button>
            </div>
          </Form.Item>
        </Form>
      );
    }
  }, [editState, rewardValues, isEditReward, successAddReward, erroAddReward,rewards]);

  const removeInfo = (objPermissions) => {
    const objPerm = JSON.parse(JSON.stringify(objPermissions))
    const _permission = Object.keys(objPerm).reduce((finalobj, key)=>{
      finalobj[key] = objPerm[key].map(obj=>{
        const _obj = obj
        if(_obj.hasOwnProperty("_info")) delete _obj["_info"]
        return _obj
      })
      
      return finalobj
    },{})
    return JSON.stringify(_permission)
  }

  const putRewards = (
    dataValues = { reward_id: Number(props.match.params.reward_id) }
  ) => {
    setIsEditReward(true);
    const requiredFields = [
      "reward_name",
      "star_price",
      "slug",
      "reward_description",
      "category",
      "thumbnail",
    ];
    const _data = new FormData();

    const map_fields = {
      "active": (action) => action ? "Y" : "N",
      "auto_claimed": (action) => action ? "Y" : "N",
      "thumbnail": (action) => action.name,
      "resource": (action) => action.name,
      "permissions": (action) => removeInfo(action),
      "conditions": (action) => removeInfo(action)
    }

    Object.keys(dataValues).forEach((field) => {
      if(field in dataValues && dataValues[field]!==undefined){
        if (field in map_fields) {
          _data.append(field, map_fields[field](dataValues[field]));
        } else {
          _data.append(field, dataValues[field]);
        }
      }
    });

    if (Object.keys(_data).indexOf("reward_id") === -1) {
      _data.append("reward_id", props.match.params.reward_id);
    }

    if (editState === "started") {
      const errors = {};
      
      if (
        Object.keys(dataValues).indexOf("max_instalments") !== -1 &&
        dataValues.max_instalments < 0
      ) {
        errors["max_instalments"] = "Este campo deve ser maior ou igual a zero";
      } else {
        delete errors["max_instalments"];
      }

      requiredFields.forEach((field) => {
        if (
          inputValues.hasOwnProperty(field) &&
          !inputValues[field]
        ) {
          errors[field] = `"${getHeadTableNames(
            field
          )}" é um campo obrigatório!`;
        }
      });
      console.log("errors",errors);
      setErroAddReward(errors);
      if (Object.keys(errors).length !== 0) {
        setIsEditReward(false);
        console.log(errors);
        return null;
      }
    }
    Auth.currentAuthenticatedUser().then((user) => {
      axios
        .put(ADMIN_REWARDS, _data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((response) => {
          const tempValues = response.data;
          delete tempValues.id;
          delete tempValues.date_created;
          delete tempValues.date_updated;
          delete tempValues.date_edited;
          delete tempValues.reward_buyed_count;
          delete tempValues.parent_reward_id;
          tempValues['all_tags'] = (tempValues['all_tags'])?tempValues['all_tags'].split(",").map(v=>{return {value:v.trim(),label:v.trim()}}):undefined

          const tempRewardsElements = rewardsElements.map((element) => {
            const tempElement = element;
            Object.keys(tempElement).forEach((key) => {
              if (typeof tempElement[key] === "function") {
                tempElement[key] = tempElement[key](tempValues);
              }
            });
            return tempElement;
          });
          setRewardsElements(tempRewardsElements);
          
          const mapRoles ={
            'string':(r)=>JSON.parse(r),
            'object':(r)=>(r)?r:{}
          }
          const _keyRolePermission = typeof tempValues['permissions']
          const _keyRoleConditions = typeof tempValues['conditions']
          
          tempValues['permissions'] = (mapRoles.hasOwnProperty(_keyRolePermission))?mapRoles[_keyRolePermission](tempValues['permissions']):{}
          tempValues['conditions'] = (mapRoles.hasOwnProperty(_keyRoleConditions))?mapRoles[_keyRoleConditions](tempValues['conditions']):{}
          if(tempValues['conditions']=='null' || !tempValues['conditions']) tempValues['conditions'] ={}
          if(tempValues['permissions']=='null' || !tempValues['permissions']) tempValues['permissions'] ={}
          
          // tempValues['conditions'] = (typeof tempValues['conditions'] == 'string' && tempValues['conditions'].length>1)?JSON.parse(tempValues['conditions']):tempValues['permissions'];

          tempValues['tags'] = (tempValues['tags'])?tempValues['tags'].split(","):undefined
          setRewardValues(tempValues);
          if (editState === "started"){
            setSuccessAddReward("Recompensa editada com sucesso!!!");
            const responseData = {}
            if ('thumbnail_upload' in tempValues) responseData['thumbnail'] = tempValues['thumbnail_upload']
            if ('resource_upload' in tempValues) responseData['resource'] = tempValues['resource_upload']
            let responseCheck = {}
            const allOKFeedback = (feedback="Dados alterados com sucesso")=>{
                setSuccessAddReward(feedback);
                setIsEditReward(false);
              
            }
            const checkResponseFiles = () => {
              let allOk = true;
              Object.keys(responseCheck).forEach((field) => {
                allOk = allOk && (responseCheck[field] != null)
              })
              return allOk
            }
            if(Object.keys(responseData).length==0){
              allOKFeedback()
            }
            Object.keys(responseData).forEach((field) => {
              const dataFile = new FormData();

              responseCheck[field] = null
              Object.keys(responseData[field]['fields']).forEach((inputField) => {
                dataFile.append(inputField, responseData[field]['fields'][inputField]);
              })
              dataValues[field] = (field == "thumbnail_upload") ? new File([dataValues[field]], "thumbnail.png", { type: dataValues[field].type }) : dataValues[field]
              dataFile.append("file", dataValues[field]);

              axios
                .post(responseData[field]['url'], dataFile, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*"
                  },
                })
                .then((_response) => {
                  // console.log("response", _response);
                  responseCheck[field] = _response
                  if (checkResponseFiles()) {
                    allOKFeedback("Arquivos Atualizados")
                  }
                }).catch((error) => {
                  setErroAddReward({ error: "Deu erro!" });
                  console.log(error);
                  setIsEditReward(false);

                })
            });

          }else{
            setIsEditReward(false);
          }
        })
        .catch((error) => {
          setErroAddReward({
            error: "Erro na alteração da recompensa.",
          });
          console.log(error);
          setIsEditReward(false);
        });
    });
  };

  const handleInputChange = (e, element) => {
    let value = e;
    inputValues.current = { ...inputValues.current, [element]: value };
    setRewardValues({
      ...rewardValues,
      [element]: value,
    });
  };

  const getHeadTableNames = (name) => {
    const names = {
      id: "Id",
      reward_name: "Nome",
      category: "Categoria",
      date_created: "Criado em",
      date_updated: "Modificado em",
      resource: "Arquivo",
      slug: "Nome único do item (sem espaços)",
      star_price: "Preço (Af$)",
      tags: "Tags",
      active: "Ativo",
      deletar: "Inativar",
      reward_description: "Descrição",
      reward_media: "URL do vídeo",
      auto_claimed: "Auto recuperável",
      max_instalments: "Máximo possuído",
      thumbnail: "Thumbnail",
      file: "Arquivo",
    };

    const resp = name in names ? names[name] : name;

    return resp;
  };
  const getFieldsByRewardType = (reward, index) => {
    
    const paternComponent = {
      input: (
        <Input
          className={index % 2 === 0 ? classes.flexRight : classes.flexLeft}
          type={reward.type}
          name="value"
          placeholder={getHeadTableNames(reward.name)}
          onChange={(e) => handleInputChange(e.target.value, reward.name)}
          required={reward.required}
          value={rewardValues[reward.name]}
          disabled={!!reward.disabled}
        />
      ),
      textArea: (
        <Input.TextArea
          className={index % 2 === 0 ? classes.flexRight : classes.flexLeft}
          type={reward.type}
          name="value"
          placeholder={getHeadTableNames(reward.name)}
          required={reward.required}
          onChange={(e) => handleInputChange(e.target.value, reward.name)}
          value={rewardValues[reward.name]}
        />
      ),
      autocomplete: (
        <AutoComplete
          className={index % 2 === 0 ? classes.flexRight : classes.flexLeft}
          name={reward.name}
          onChange={(e) => handleInputChange(e, reward.name)}
          options={[...(reward.options?reward.options:[]),...(reward.hasOwnProperty("all_tags")?reward.all_tags:[])]}
          mode={reward.mode}
          tokenSeparators={[',']}
          tagRender={tagRender}
          required={reward.required}
          value={rewardValues[reward.name]}
        ></AutoComplete>
      ),
      select: (
        <Select
          className={index % 2 === 0 ? classes.flexRight : classes.flexLeft}
          name={reward.name}
          onChange={(e) => handleInputChange(e, reward.name)}
          options={[...(reward.options?reward.options:[]),...(reward.hasOwnProperty("all_tags")?reward.all_tags:[])]}
          mode={reward.mode}
          tokenSeparators={[',']}
          tagRender={tagRender}
          required={reward.required}
          value={rewardValues[reward.name]}
        ></Select>
      ),
      upload: (
        <Upload
          listType="picture-card"
          onChange={(e) => {
            setRewardsElements(
              rewardsElements.map((_reward) => {
                if (_reward.name === reward.name) {
                  // _reward.fileList = [{ url: e.fileList[0].originFileOb }];
                  _reward.fileList = e.fileList;
                }
                return _reward;
              })
            );
            handleInputChange(e.fileList[0].originFileObj, reward.name);
          }}
          fileList={reward.fileList}
          maxCount={1}
          showUploadList={{
            showRemoveIcon: false,
          }}
          action={(file) => {
            console.log(file);
          }}
          beforeUpload={Upload.LIST_IGNORE}
          accept={reward.accept}
          required={reward.required}
        >
          <div>
            <PlusOutlined />
          </div>
        </Upload>
      ),
      checkbox: (
        <Checkbox
          checked={
            inputValues.current.hasOwnProperty(reward.name)
              ? inputValues.current[reward.name] === true
              : rewardValues[reward.name] === "Y"
              ? true
              : false
          }
          onChange={(e) => handleInputChange(e.target.checked, reward.name)}
        ></Checkbox>
      ),
    };
    return paternComponent[reward.typeComponent];
  };

  const [content, setContent] = useState(<>{spinner}</>);

  return (
    <div>
      <h2>Editar Recompensa</h2>
      {content}
    </div>
  );
};

export default EditReward;
