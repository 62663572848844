import React, { useRef, useEffect, useState } from "react";
import classes from "./ModalAddCompany.module.scss";
import { Auth } from "aws-amplify";
import { Row, Col } from "antd";
import axios from "axios";
import { ADMIN_CREATE_COMPANY } from "../../../constants/api";
import InputMask from "react-input-mask";
import Spinner from "../../Spinner";

const ModalAddCompany = ({
  setShowModalNewCompany,
  setShowModalCompanyAdded,
}) => {
  const myRefName = useRef();

  let modalColor = null;

  let content_modal = null;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openState, setModal] = useState(classes.WrapModalhide);
  const [companyName, setCompanyName] = useState();
  const [companyUid, setCompanyUid] = useState();
  const [usersGo, setUsersGo] = useState();
  const [usersSmart, setUsersSmart] = useState();
  const [planType, setPlanType] = useState();
  const [emailAdmin, setEmailAdmin] = useState();

  useEffect(() => {
    setModal(classes.WrapModal);
  }, []);

  let errorsMap = new Map([
    ["Request failed with status code 409", "existem campos sem preenchimento"],
    [
      "Request failed with status code 412",
      "email do super admin já existente na base",
    ],
    [
      "Request failed with status code 500",
      "falha ao cadastrar usuário no banco. tente novamente mais tarde",
    ],
    [
      "Request failed with status code 523",
      "falha ao cadastrar empresa no banco. tente novamente mais tarde",
    ],
  ]);

  const createCompany = () => {
    Auth.currentAuthenticatedUser().then((u) => {
      let data = {
        company: {
          company_uid: companyUid,
          company_name: companyName,
          limit_users: usersGo,
          limit_users_smart: usersSmart,
          owned_journeys: usersSmart,
          plan_permission: planType,
        },
        user: {
          user_email: emailAdmin,
          company_uid: companyUid,
        },
      };

      axios
        .post(ADMIN_CREATE_COMPANY, data, {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((resp) => {
          setLoading(false);
          if (resp.status === 200) {
            setShowModalNewCompany(false);
            setShowModalCompanyAdded(true);
          }
        })
        .catch((e) => {
          for (let [key, value] of errorsMap) {
            if (key === e.message) {
              setError(value);
              break;
            }
          }
        });
    });
  };

  let SelectPaymentMethod = (
    <select
      tabindex="3"
      className={classes.Dropdown}
      onChange={(e) => {
        let valueSelect = e.target.value;
        setPlanType(valueSelect);
      }}
    >
      <option className={classes.Option} value="Selecione Forma de Pagamento">
        Selecione Um Tipo de Plano
      </option>
      <option className={classes.Option} value="01">
        Go
      </option>
      <option className={classes.Option} value="10">
        Smart
      </option>
      <option className={classes.Option} value="11">
        Go e Smart
      </option>
    </select>
  );
  
  //Content
  content_modal = (
    <div className={openState} ref={myRefName}>
      <div className={classes.Content}>
        <div className={modalColor}>
          <div className={classes.TopBar}>
            <div
              className={classes.CloseButtonX}
              onClick={() => {
                setModal(classes.WrapModalhide);
                setShowModalNewCompany(false);
              }}
            >
              X
            </div>
          </div>
          <div className={classes.Information}>
            <div className={classes.Title}>cadastro de nova empresa</div>

            <div>
              <div className={classes.ModalLabel}>
                <Row gutter={[16, 24]}>
                  <Col className="gutter-row" span={12}>
                    <div>Nome da Empresa:</div>
                    <InputMask
                      tabindex="1"
                      required
                      maskChar={null}
                      className={classes.InputModal}
                      placeholder={"nome empresa"}
                      onChange={(e) => {
                        let valueDigited = e.target.value;
                        setCompanyName(valueDigited);
                      }}
                    />
                    <div>Tipo de Plano:</div>
                    {SelectPaymentMethod}
                    <div className={classes.InputModalUsersMain}>
                      {(planType === "01" || planType === "11") && (
                        <div>
                          <div>N° Usuários Go:</div>
                          <InputMask
                            tabindex="5"
                            required
                            maskChar={null}
                            className={classes.InputModalUsers}
                            placeholder={"ex: 10"}
                            mask="99999999"
                            onChange={(e) => {
                              let valueDigited = e.target.value;
                              setUsersGo(valueDigited);
                            }}
                          />
                        </div>
                      )}
                      {(planType === "10" || planType === "11") && (
                        <div>
                          <div>N° Usuários Smart:</div>
                          <InputMask
                            tabindex="6"
                            required
                            maskChar={null}
                            className={classes.InputModalUsers}
                            placeholder={"ex: 10"}
                            mask="99999999"
                            onChange={(e) => {
                              let valueDigited = e.target.value;
                              setUsersSmart(valueDigited);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <div>Código da Empresa:</div>
                    <InputMask
                      tabindex="2"
                      required
                      maskChar={null}
                      className={classes.InputModal}
                      placeholder={"ex.: EMP001"}
                      mask="******"
                      onChange={(e) => {
                        let valueDigited = e.target.value;
                        setCompanyUid(valueDigited);
                      }}
                    />
                    <div>Email Super Admin:</div>
                    <InputMask
                      tabindex="4"
                      required
                      maskChar={null}
                      className={classes.InputModal}
                      placeholder={"email"}
                      onChange={(e) => {
                        let valueDigited = e.target.value;
                        setEmailAdmin(valueDigited);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <button
              tabindex="7"
              onClick={() => {
                createCompany();
              }}
              className={classes.CloseButtonText}
            >
              Adicionar Empresa
            </button>
            {error !== "" && <div className={classes.ErrorMessage}>{error}</div>}
            {loading && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );

  return <section>{content_modal}</section>;
};

export default ModalAddCompany;
