import { Auth } from "aws-amplify";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";

export default function useFetch({
  url: _url,
  method: _method = "GET",
  data: _data = null,
  header: _header = null,
  config: _config = null,
  noAuth: _noAuth = false,
  autoInit: _autoInit = true,
}) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useCallback(
    ({
      url = _url,
      method = _method,
      data = _data,
      header = _header,
      config = _config,
      noAuth = _noAuth,
      onError = () => {},
      onSuccess = () => {},
    }) => {
      // console.log("data", data);
      setIsLoading(true);
      const _finalHeader = noAuth
        ? {}
        : { Authorization: Auth?.user?.signInUserSession?.idToken?.jwtToken };
    
      let _tempData = data;
      try {
        _tempData = JSON.stringify(data);
      } catch (e) {
        // console.log("catch _tempData = JSON.stringify(data);");
      }
      axios({
        method: method,
        url: url,
        headers: { ...header, ..._finalHeader },
        data: _tempData,
      })
        .then((res) => {
          // console.log("res1", res);
          setResponse(res.data);
          onSuccess();
        })
        .catch((error) => {
          try {
            // console.log("err1", error);
            Auth.currentAuthenticatedUser()
              .then((cognitoUser) => {
                Auth.currentSession().then((currentSession) => {
                  cognitoUser.refreshSession(
                    currentSession.refreshToken,
                    (err, session) => {
                      try {
                        axios({
                          method: method,
                          url: url,
                          headers: {
                            Authorization: session.idToken.jwtToken,
                          },
                          data: _tempData,
                        })
                          .then((res) => {
                            // console.log("res", res);
                            // TODO talk to Rafa to return the updated or created object
                            // if (method !== "put" && method !== "post")
                            setResponse(res.data);
                            onSuccess();
                          })
                          .catch((err) => {
                            // console.log("err1", err);
                            setError(err);
                            onError();
                          })
                          .finally(() => setIsLoading(false));
                      } catch (error) {
                        // console.log("error2", error);
                      }
                    }
                  );
                });
              })
              .catch((err) => {
                setError(err);
              })
              .finally(() => setIsLoading(false));
          } catch (error) {
            setError(error);
            setIsLoading(false);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [_url, _method, _data, _header, _config, _noAuth]
  );

  useEffect(() => {
    if (_autoInit)
      fetch({
        url: _url,
        method: _method,
        data: _data,
        header: _header,
        config: _config,
        noAuth: _noAuth,
      });
  }, [_url, _method, _data, _header, _config, _noAuth, _autoInit, fetch]);
  return { response, isLoading, error, fetch };
}
