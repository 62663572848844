import React, { Component } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 55, color: "#0a4148" }} spin />
);

class Spinner extends Component {
  render() {
    return (
      <Spin
        style={{
          margin: "auto",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems:"center"
        }}
        indicator={antIcon}
      />
    );
  }
}

export default Spinner;
