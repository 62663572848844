import React, { useState } from "react";
import classes from "./AudiofocoUsers.module.scss";
import H1 from "../../Components/Titles/H1";
import axios from "axios";
import { Input, Alert, Form, Button } from "antd";
import {
    ADMIN_GET_AUDIOFOCO_USERS,
    ADMIN_AUDIOFOCO_END_JOURNEY,
} from "../../constants/api";
import Spinner from "../../Components/Spinner";
import { Auth } from "aws-amplify";
// import ButtonFunction from "../../Components/Buttons/ButtonFunction/ButtonFunction";
// const { Search } = Input;

const AudiofocoUsers = () => {
    const [state, setState] = useState({
        error: "",
        progress: 0,
        users: [],
        all_users: [],
        user_email: "",
        user_sub: "",
        product_name: "",
        modal_visible: false,
        modal_visible_free: false,
        loading: false,
        mensagem: 0,
        message_no_user: false,
        change_plan: false,
    });
    const [getUsers, setGetUsers] = useState();
    const [disableFinalizeJourneyBtn, setDisableFinalizeJourneyBtn] =
        useState(false);

    const searchUserRegistry = (registry, company_uid) => {
        setState({ loading: true, error: "" });
        setGetUsers([]);
        let enc_user_uid = registry ? registry.trim() : "";
        enc_user_uid = enc_user_uid ? encodeURI(enc_user_uid) : "";
        enc_user_uid = enc_user_uid ? encodeURIComponent(enc_user_uid) : "";
        Auth.currentAuthenticatedUser().then((u) => {
            axios
                .get(ADMIN_GET_AUDIOFOCO_USERS(enc_user_uid, company_uid), {
                    headers: {
                        Authorization:
                            Auth.user.signInUserSession.idToken.jwtToken,
                    },
                })
                .then((users) => {
                    setGetUsers(users.data);
                    if (Object.keys(users.data).length > 0) {
                        setState({
                            ...state,
                            error: "",
                            loading: false,
                            message_no_user: false,
                        });
                    } else {
                        setState({
                            error: "Não foi possivel encontrar esse usuário",
                        });
                    }
                })
                .catch((e) => {
                    setState({
                        error: "Não foi possivel encontrar esse usuário",
                    });
                });
        });
    };

    const finalizeJourney = (userUid) => {
        setDisableFinalizeJourneyBtn(true);
        const data = {
            user_uid: String(userUid),
        };
        Auth.currentAuthenticatedUser().then((u) => {
            axios
                .put(ADMIN_AUDIOFOCO_END_JOURNEY, data, {
                    headers: {
                        Authorization:
                            Auth.user.signInUserSession.idToken.jwtToken,
                    },
                })
                .then((t) => {
                    setDisableFinalizeJourneyBtn(false);
                    alert("Finalizado com sucesso!");
                })
                .catch((e) => {
                    console.log("e", e);
                    setState({
                        error: "Não foi possivel realizar a operação",
                    });
                    setDisableFinalizeJourneyBtn(false);
                });
        });
    };

    const renderUser = (user, k) => {
        return (
            <tr className={classes.DataTable} key={k}>
                <td>{user?.id ? user?.id : user?.user_uid}</td>
                <td>{user?.company_uid}</td>
                <td>{user?.registry}</td>
                <td>{user?.professional_email}</td>
                <td>{user?.shots}</td>
                <td>
                    {user?.journey_description
                        ? user?.journey_description
                        : "Não iniciado"}
                </td>
                <td>
                    {user?.date_IA_start ? user?.date_IA_start : "Não iniciado"}
                </td>
                <td>
                    {user?.date_IA_finish
                        ? user?.date_IA_finish
                        : "Não iniciado"}
                </td>
                <td>
                    <Button
                        type="primary"
                        onClick={() => {
                            finalizeJourney(
                                user?.id ? user?.id : user?.user_uid,
                                user?.company_uid,
                                user?.registry
                            );
                        }}
                        text={
                            user?.journey_description === "IA_Avaliation"
                                ? "FINALIZAR"
                                : user?.journey_description === "end_journey"
                                ? "FINALIZADO"
                                : "NÃO SE APLICA"
                        }
                        disabled={
                            user?.journey_description !== "IA_Avaliation" ||
                            disableFinalizeJourneyBtn
                        }
                    >
                        {user?.journey_description === "IA_Avaliation"
                            ? "FINALIZAR"
                            : user?.journey_description === "end_journey"
                            ? "FINALIZADO"
                            : String(user?.journey_description).toUpperCase()}
                    </Button>
                </td>
            </tr>
        );
    };

    const renderUsersTable = (user) => {
        return (
            <div>
                <table className="table">
                    <thead>
                        <tr className={classes.DataTable}>
                            <th scope="col">User Id</th>
                            <th scope="col">Código Empresa</th>
                            <th scope="col">Registry</th>
                            <th scope="col">Email do admin</th>
                            <th scope="col">Shots</th>
                            <th scope="col">journey_description</th>
                            <th scope="col">Data início do treino</th>
                            <th scope="col">Data fim do treino</th>
                            <th scope="col">Finalizar jornada</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user &&
                            user
                                .sort((a, b) => b.id - a.id)
                                .map((plan, k = +1, all) =>
                                    renderUser(plan, k, all.length)
                                )}
                    </tbody>
                </table>
                <hr />
            </div>
        );
    };

    const onFinishFailed = (e) => {
        console.log(e);
    };
    const onFinish = (user) => {
        searchUserRegistry(user.Registry, user.Empresa);
    };

    return (
        <>
            <div>
                <H1>Usuários Audiofoco</H1>
                <div className="row">
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item label="Registry" name="Registry" rules={[]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Empresa"
                            name="Empresa"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor informe o Empresa!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                    {state.error && (
                        <Alert message={state.error} type="warning" />
                    )}
                    <div className={classes.DataTable}>
                        {getUsers &&
                            Object.keys(getUsers).length > 0 &&
                            renderUsersTable(getUsers)}
                        {state.loading && <Spinner />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AudiofocoUsers;
