/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import classes from "./CancelPlan.module.scss";
import axios from "axios";
import H1 from "../../Components/Titles/H1";
import Spinner from "../../Components/Spinner";
import ModalDeleteUser from "../../Components/Modais/ModalDeleteUser/ModalDeleteUser";
import ModalUserDeleted from "../../Components/Modais/ModalUserDeleted/ModalUserDeleted";
import ModalUserPaid from "../../Components/Modais/ModalUserPaid/ModalUserPaid";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { DatePicker, Select } from "antd";
import ExportCSV from "../../Components/ExportCSV";
import PageNoContent from "../../assets/images/404.svg";
import { useRef } from "react";
import {
  ADMIN_CANCEL_PLAN_BY_DATE,
  ADMIN_CANCEL_PLAN_BY_MONTH,
  ADMIN_CANCEL_PLAN_INFO,
} from "../../constants/api";

export default function CancelPlan() {
  const [showModal, setShowModal] = useState(false);
  const [showModalDeleted, setShowModalDeleted] = useState(false);
  const [showModalIsPaid, setShowModalIsPaid] = useState(false);
  const [userPosition, setUserPosition] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [selecionado, setSelecionado] = useState(null);
  const [startDateByDate, setStartDateByDate] = useState(null);
  const [endDateByDate, setEndDateByDate] = useState(null);
  const [dateByMonth, setDateByMonth] = useState(null);
  const [dateByYear, setDateByYear] = useState(null);
  const [csvExporting, setCsvExporting] = useState([]);
  const [IsNoContent, setIsNoContent] = useState(false);

  const cardFilterRef = useRef(null);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeSelectYear = (value) => {
    setDateByYear(value);
  };

  const onChangeSelectMonth = (value) => {
    setDateByMonth(value);
  };

  const onChangeStart = (date, dateString) => {
    setStartDateByDate(dateString);
  };

  const onChangeEnd = (date, dateString) => {
    setEndDateByDate(dateString);
  };

  const handleSelecao = (valor) => {
    setSelecionado(valor);
  };

  const mesesDoAno = [
    { value: "01", label: "Janeiro" },
    { value: "02", label: "Fevereiro" },
    { value: "03", label: "Março" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Maio" },
    { value: "06", label: "Junho" },
    { value: "07", label: "Julho" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" },
  ];

  // Array com os últimos 10 anos
  const anoAtual = new Date().getFullYear();
  const ultimos10Anos = Array.from({ length: 10 }, (_, index) => ({
    value: (anoAtual - index).toString(),
    label: (anoAtual - index).toString(),
  }));

  const [state, setState] = useState({
    error: "",
    progress: 0,
    users: [],
    all_users: [],
    user_email: "",
    user_sub: "",
    product_name: "",
    modal_visible: false,
    modal_visible_free: false,
    loading: false,
    mensagem: 0,
    message_no_user: false,
    change_plan: false,
  });

  const user_types_map = {
    individual_free: "Usuário Gratuito",
    parent_free: "Usuário Gratuito (Pai) - Com Usuários Vinculados",
    patient_paid: "Usuário Pago - Vinculado a Outro Usuário",
    individual_paid: "Usuário Pago",
    limbo: "Email não Verificado",
    patient_free: "Usuário Gratuito - Vinculado a Outro Usuário",
    parent_paid: "Usuário Pago (Pai) - Com Usuários Vinculados",
    individual_trial: "Usuário no Trial",
    grand_paid:
      "Usuário Pago - Vinculado a Outro Usuário e Com Usuários Vinculados",
    grand_free:
      "Usuário Gratuito - Vinculado a Outro Usuário e Com Usuários Vinculados",
  };

  const user_free_map = {
    individual_free: "Gratuita",
    parent_free: "Gratuita",
    patient_free: "Gratuita",
    grand_free: "Gratuita",
    individual_trial: "Gratuita",
    limbo: "Gratuita",
  };

  useEffect(() => {
    setShowCard(false);
    setState({ loading: true, error: "" });
    Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_CANCEL_PLAN_INFO, {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((users) => {
          if (Object.keys(users.data).length > 0) {
            setState({
              ...state,
              users: users.data,
              error: "",
              loading: false,
              message_no_user: false,
            });
            setIsNoContent(false);
          } else {
            setState({ error: "Não foi possivel encontrar esse email" });
            setIsNoContent(true);
          }
        })
        .catch((e) => {
          setState({ error: "Não foi possivel encontrar esse email" });
          setIsNoContent(true);
        });
    });
  }, []);

  const searchUserByDate = () => {
    setShowCard(false);
    setState({ loading: true, error: "" });

    Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_CANCEL_PLAN_BY_DATE(startDateByDate, endDateByDate), {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((response) => {
          if (response.status === 204) {
            // Lida com o status 204 (No Content)
            setIsNoContent(true);
            setState({
              ...state,
              error: "",
              loading: false,
              message_no_user: false,
            });
          } else if (Object.keys(response.data).length > 0) {
            // Se houver dados na resposta
            setIsNoContent(false);
            setState({
              ...state,
              users: response.data,
              error: "",
              loading: false,
              message_no_user: false,
            });
          } else {
            // Se a resposta não for 204 e não houver dados
            setState({ error: "Não foi possível encontrar usuários" });
            setIsNoContent(true);
          }
        })
        .catch((error) => {
          // Se ocorrer um erro na chamada
          setState({ error: "Não foi possível encontrar usuários" });
          setIsNoContent(true);
        });
    });
  };

  const searchUserByYearMonth = () => {
    setShowCard(false);
    setState({ loading: true, error: "" });
    Auth.currentAuthenticatedUser().then((u) => {
      axios
        .get(ADMIN_CANCEL_PLAN_BY_MONTH(dateByYear, dateByMonth), {
          headers: {
            Authorization: Auth.user.signInUserSession.idToken.jwtToken,
          },
        })
        .then((users) => {
          if (users.status === 204) {
            // Lida com o status 204 (No Content)
            setIsNoContent(true);
            setState({
              ...state,
              error: "",
              loading: false,
              message_no_user: false,
            });
          } else if (Object.keys(users.data).length > 0) {
            setIsNoContent(false);
            setState({
              ...state,
              users: users.data,
              error: "",
              loading: false,
              message_no_user: false,
            });
            setShowCard(!showCard);
          } else {
            setState({ error: "Não foi possivel encontrar esse email" });
            setIsNoContent(true);
          }
        })
        .catch((e) => {
          setState({ error: "Não foi possivel encontrar esse email" });
          setIsNoContent(true);
        });
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cardFilterRef.current &&
        !cardFilterRef.current.contains(event.target) &&
        !event.target.closest(".ant-select-dropdown") &&
        !event.target.closest(".ant-picker-dropdown")
      ) {
        setShowCard(false);
      }
    };

    // Adicione o ouvinte de evento ao documento
    document.addEventListener("mousedown", handleClickOutside);

    // Limpe o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cardFilterRef]);

  const renderUser = (user, k) => {
    return (
      <tr className={classes.DataTable} key={k}>
        <td>{user.email}</td>
        <td>{user.log_date}</td>
        <td>{user.infos?.next_charge}</td>
        <td>{user.reason}</td>
        <td>{user.infos?.name}</td>
        <td>{user.infos?.first_charge}</td>
        <td>{user.infos?.price}</td>
        <td>{user.infos?.payment_method}</td>
        <td>{user.actual_status}</td>
        <td>{user.infos?.order_id}</td>
      </tr>
    );
  };

  const renderUsersTable = (users) => {
    return (
      <div>
        <table className="table">
          <thead>
            <tr className={classes.DataTable}>
              <th scope="col">E-mail - usuário</th>
              <th scope="col">Data do cancelamento</th>
              <th scope="col">Data limite do plano</th>
              <th scope="col">Motivo</th>
              <th scope="col">Plano</th>
              <th scope="col">Data de compra</th>
              <th scope="col">Valor</th>
              <th scope="col">Tipo</th>
              <th scope="col">Status</th>
              <th scope="col">OrderID - Compra</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(users).length > 0 &&
              users.map((plan, k = +1, all) =>
                renderUser(plan.infos_cancelation, k, all.length)
              )}
          </tbody>
        </table>
        <hr />
      </div>
    );
  };

  const headers = [
    { label: "E-mail - usuário", key: "E-mail - usuário" },
    { label: "Data do cancelamento", key: "Data do cancelamento" },
    { label: "Data limite do plano", key: "Data limite do plano" },
    { label: "Motivo", key: "Motivo" },
    { label: "Plano", key: "Plano" },
    { label: "Data de compra", key: "Data de compra" },
    { label: "Valor", key: "Valor" },
    { label: "Tipo", key: "Tipo" },
    { label: "Status", key: "Status" },
    { label: "OrderID - Compra", key: "OrderID - Compra" },
  ];

  useEffect(() => {
    // Essa lógica será executada uma vez ao montar o componente
    setCsvExporting(
      state.users?.map((user) => ({
        "E-mail - usuário": user.infos_cancelation.email,
        "Data do cancelamento": user.infos_cancelation.log_date,
        "Data limite do plano": user.infos_cancelation.infos?.next_charge,
        Motivo: user.infos_cancelation.reason,
        Plano: user.infos_cancelation.infos?.name,
        "Data de compra": user.infos_cancelation.infos?.first_charge,
        Valor: user.infos_cancelation.infos?.price,
        Tipo: user.infos_cancelation.infos?.payment_method,
        Status: user.infos_cancelation.actual_status,
        "OrderID - Compra": user.infos_cancelation.infos?.order_id,
      }))
    );
  }, [state]);

  const UsersByOrder = state.users?.sort((a, b) => {
    const dateA = new Date(a.infos_cancelation.log_date);
    const dateB = new Date(b.infos_cancelation.log_date);

    return dateB - dateA;
  });

  return (
    <div>
      {showModal && (
        <ModalDeleteUser
          users={state.users}
          setShowModal={setShowModal}
          setShowModalDeleted={setShowModalDeleted}
          userPosition={userPosition}
          // setForceUpdate={setForceUpdate}
          // forceUpdate={forceUpdate}
        />
      )}
      {showModalDeleted && (
        <ModalUserDeleted
          users={state.users}
          setShowModalDeleted={setShowModalDeleted}
          // setForceUpdate={setForceUpdate}
          // forceUpdate={forceUpdate}
        />
      )}
      {showModalIsPaid && (
        <ModalUserPaid
          users={state.users}
          setShowModalIsPaid={setShowModalIsPaid}
          // setForceUpdate={setForceUpdate}
          // forceUpdate={forceUpdate}
        />
      )}
      <H1>CANCELAMENTOS AFINANDO</H1>
      <div className="row">
        <div className={classes.ButtonsArea}>
          <button
            onClick={() => setShowCard(!showCard)}
            className={classes.Filter}
          >
            Filtrar por data
          </button>
          <button className={classes.CsvExport}>
            <ExportCSV
              name={"Exportar planilha"}
              className={classes.CsvExportField}
              data={csvExporting || []}
              filename="dados.csv"
              headers={headers}
            />
          </button>
        </div>
        {showCard && (
          <div ref={cardFilterRef} className={classes.CardFilter}>
            Filtrar por:
            <div className={classes.CheckboxArea}>
              <div className={classes.Checkbox}>
                <input
                  type="radio"
                  id="radioMes"
                  checked={selecionado === "mes"}
                  onChange={() => handleSelecao("mes")}
                />
                <label htmlFor="radioMes">Mês</label>
              </div>
              <div className={classes.Checkbox}>
                <input
                  type="radio"
                  id="radioPeriodoDias"
                  checked={selecionado === "periodoDias"}
                  onChange={() => handleSelecao("periodoDias")}
                />
                <label htmlFor="radioPeriodoDias">Período de dias</label>
              </div>
            </div>
            <div className={classes.DatepickerArea}>
              {selecionado === "mes" && (
                <div>
                  <div className={classes.MonthFilter}>
                    <div className={classes.Filter}>
                      <div>Mês:</div>{" "}
                      <Select
                        showSearch
                        placeholder="Selecione um mês"
                        optionFilterProp="children"
                        onChange={onChangeSelectYear}
                        filterOption={filterOption}
                        options={mesesDoAno}
                        style={{ minWidth: "151px" }}
                      />
                    </div>
                    <div className={classes.Filter}>
                      <div>Ano:</div>{" "}
                      <Select
                        showSearch
                        placeholder="Selecione um ano"
                        optionFilterProp="children"
                        onChange={onChangeSelectMonth}
                        filterOption={filterOption}
                        options={ultimos10Anos}
                        style={{ minWidth: "151px" }}
                      />
                    </div>
                  </div>
                  <div className={classes.ApplyFilterDatepickerArea}>
                    <button
                      onClick={() => {
                        searchUserByYearMonth();
                        setIsNoContent(false);
                      }}
                      className={classes.ApplyFilterDatepicker}
                    >
                      Aplicar filtro
                    </button>
                  </div>
                </div>
              )}
              {selecionado === "periodoDias" && (
                <div>
                  <div className={classes.DaysFilter}>
                    <div className={classes.Filter}>
                      <div>De:</div>
                      <DatePicker
                        format="DD-MM-YYYY"
                        onChange={onChangeStart}
                        placeholder="Selecione data"
                      />
                    </div>
                    <div className={classes.Filter}>
                      <div> Até:</div>
                      <DatePicker
                        format="DD-MM-YYYY"
                        onChange={onChangeEnd}
                        placeholder="Selecione data"
                      />
                    </div>
                  </div>
                  <div className={classes.ApplyFilterDatepickerArea}>
                    <button
                      onClick={() => {
                        searchUserByDate();
                        setIsNoContent(false);
                      }}
                      className={classes.ApplyFilterDatepicker}
                    >
                      Aplicar filtro
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div></div>
          </div>
        )}
        <div className="col-sm-12">
          {IsNoContent ? (
            <div className={classes.NoContentArea}>
              <div>
                Parece que não há resultados para esse filtro. Que tal tentar
                algo diferente?
              </div>
              <img src={PageNoContent} alt="Imagem Exemplo" />
            </div>
          ) : (
            state.users &&
            Object.keys(state.users).length > 0 &&
            renderUsersTable(UsersByOrder)
          )}
          {state.loading && <Spinner />}
        </div>
      </div>
    </div>
  );
}
