import React from "react";
import { CSVLink } from "react-csv";

const ExportCSV = ({ data, filename, className, name, headers }) => {
  // Mapeia os dados para um formato adequado para o react-csv
  const csvData = data.map((item) => headers.map((header) => item[header.key]));

  return (
    <CSVLink
      separator={";"}
      className={className}
      data={[headers.map((header) => header.label), ...csvData]}
      filename={filename || "exported_data.csv"}
    >
      {name}
    </CSVLink>
  );
};

export default ExportCSV;
