import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom'

import classes from "./DadosUsuários.module.scss";
import H1 from "../../Components/Titles/H1";
import axios from "axios";
import { Input, Alert, Tooltip, Table, Tag } from "antd";
import { ADMIN_SEARCH_BY_ANY } from "../../constants/api";
import { Auth } from "aws-amplify";
import * as routes from "./../../constants/routes";
import Spinner from "../../Components/Spinner";
import ButtonLink from "../../Components/Buttons/ButtonLink/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faUserCheck, faUserTimes } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";

const { Search } = Input;

const DataUsers = () => {
  const [state, setState] = useState({
    error: "",
    progress: 0,
    users: [],
    all_users: [],
    user_email: "",
    search: "",
    user_sub: "",
    product_name: "",
    error: "",
    modal_visible: false,
    modal_visible_free: false,
    localstorage_check: false,
    mensagem: 0,
    message_no_user: false,
    change_plan: false,
  });
  const [searchEmail,setSearchEmail] = useState("")
  const [loading,setLoading] = useState(false)
  const map_type_user_colors = {
    "default": "geekblue",
    "FREE": "volcano",
    "PAID": "green",
    "LIMBO": "RED"
  }
  const [columnsSearch, setColumnsSearch] = useState([
    {
      title: 'Sub',
      dataIndex: 'sub',
      key: 'sub',
      onFilter: (value, record) => record.sub.indexOf(value) === 0,
      filterSearch: true,
      sorter: (a, b) => a.sub.localeCompare(b.sub),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Given Name',
      dataIndex: 'given_name',
      key: 'given_name',
      onFilter: (value, record) => record.given_name.indexOf(value) === 0,
      filterSearch: true,
      sorter: (a, b) => a.given_name.localeCompare(b.given_name),
      sortDirections: ['descend', 'ascend'],


    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      onFilter: (value, record) => record.email.indexOf(value) === 0,
      filterSearch: true,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: 'Tipo do Usuário',
      dataIndex: 'type_user',
      key: 'type_user',
      onFilter: (value, record) => record.type_user.indexOf(value) === 0,
      filterSearch: true,
      sorter: (a, b) => a.type_user.localeCompare(b.type_user),
      sortDirections: ['descend', 'ascend'],
      render: (record) => {
        let textToColor = record.split(" ").pop()
        let color = (Object.hasOwn(map_type_user_colors, textToColor) ? map_type_user_colors[textToColor] : map_type_user_colors["default"])
        return <>
          <Tag color={color} key={record}>
            {record}
          </Tag>
        </>
      }

    },
    {
      title: 'Data de Cadastro',
      dataIndex: 'user_created',
      key: 'user_created',
      onFilter: (value, record) => {
        return record.user_created.isSame(value) 
      },
      sorter: (a, b) => b['user_created'].diff(a['user_created']),
      filterSearch: true,
      render: (record) => record.format("DD/MM/YYYY HH:mm:ss"),
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: 'Ações',
      render: (_, record) => {
        return <><ButtonLink
          to={{
            pathname: `/admin/planos-do-usuario/details/${record.email}`
          }}
          className={classes.ButtonSeeMore}
          text="Ver Mais"
        /></>
      }
    },
  ])
  const getContent = () => {

    return (
      <div>
        <H1>PLANOS DO USUÁRIO</H1>
        <div className="row">

          <div className="col-sm-3">
            <Search
              placeholder="Email, Name, Sub, OrderID"
              onSearch={(value) => searchUserByEmail(value)}
              value={searchEmail} 
              onChange={(e) => {setSearchEmail( e.target.value)}}
              size="large"
              style={{ borderColor: "#fff" }}
              loading={state.loading}
            />
            {state.error && <Alert message={state.error} type="warning" />}
          </div>

          <div className="col-sm-12">
            {loading ? <Spinner /> : state.users && state.users.length > 0 && <Table sticky={{ offsetHeader: 80 }} columns={columnsSearch} dataSource={state.users} rowKey="sub" />}
          </div>
        </div>
      </div>
    )
  }
  const [contentSearch, setContentSearch] = useState(getContent())

  useEffect(() => {
    if ((!state.users || state.users.length == 0) && !state.localstorage_check && !state.error) {
      const _state = state;
      _state.localstorage_check = true
      const search = localStorage.getItem("search")
      const users = localStorage.getItem("usersSearch")
      if (!users) {setState(_state); return; }

      const searchData = moment(localStorage.getItem("searchData"))
      console.log(moment().diff(searchData))
      if (moment().diff(searchData, 'days') >= 1) { setState(_state); return; }
      setSearchEmail(search)

      updateUsers(JSON.parse(users), _state)
    
    }

    setContentSearch(getContent())
  }, [state, columnsSearch,searchEmail,loading])

const updateUsers = (users, _state = state) => {
  let new_users = JSON.parse(JSON.stringify(users)).map(user => {
    user['user_created'] = moment(user['user_created'].replaceAll("T", " "));
    user['type_user'] = (user['type_user']).replaceAll("_", " ").toUpperCase();
    return user
  })
  new_users.sort((a, b) => b['user_created'].diff(a['user_created']))
  setColumnsSearch(columnsSearch.map(colum => {
    if (!colum['dataIndex']) return colum
    colum['filters'] = new_users.reduce((items, item) => {
      let key = item[colum['dataIndex']]
      if(key instanceof  moment) key = key.format("DD/MM/YYYY HH:mm:ss")

      if (!Object.hasOwn(items, item)) items[key] = item[colum['dataIndex']];
      return items
    },
      {})
    colum['filters'] = Object.keys(colum['filters']).map(k => { 
      return { "text": k, "value": colum['filters'][k] } 
    })
    return colum
  }))
  setState({
    ..._state,
    users: new_users,
    error: '',
    loading: false,
    message_no_user: false,
  });
}

const searchUserByEmail = (email) => {
  if (!email) return
  // setState({ loading: true, error: '', search: email });
  setLoading(true)
  localStorage.setItem("search", email)
  axios
    .get(ADMIN_SEARCH_BY_ANY(email), {
      headers: {
        Authorization: Auth.user.signInUserSession.idToken.jwtToken
      }
    })
    .then((users) => {
      setLoading(false)
        
      let newUseSearch = "[]"
      if (Object.keys(users.data).length > 0) {
        newUseSearch = JSON.stringify(users.data)
        localStorage.setItem("searchData", moment().format("DD/MM/YYYY"))
        updateUsers(users.data)
      } else {
        setState({ error: "Não foi possivel encontrar esse email" });
        console.log("Não foi possivel encontrar esse email");
      }
      localStorage.setItem("usersSearch", newUseSearch)
    })
    .catch((e) => {
      setLoading(false)
      setState({ error: "Não foi possivel encontrar esse email" });
    });
};


return contentSearch
};

export default DataUsers;
